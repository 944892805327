import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'

const TextFormView = ({ item, index }) => {
    return (
        <>
            <Stack >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography style={{ color: 'gray' }} fontSize={{ sx: "12px", xs: "14px" }}>
                            <span style={{
                                color: 'black',
                                fontSize: "18px",

                            }}>{index}.{" "}</span>
                            {item?.question}</Typography>
                        <div style={{ color: "black", }}>&nbsp;&nbsp;&nbsp; {item?.answer ? item?.answer : "No Answer Given"}</div>
                    </Grid>
                </Grid>
            </Stack>
        </>
    )
}

export default TextFormView
import {
  Box,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
  Tab,
  Tabs,
  Grid,
} from "@mui/material";
import React from "react";
import { Input } from "../../../components/Common";
import { v4 as uuidv4 } from "uuid";
import MetrixTypeForm from "./MetrixTypeForm";
import MetrixRowFixType from "./MetrixRowFixType";
import PropTypes from "prop-types";
import SkipLogic from "../SkipLogic";

///
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
////

const MatrixMain = ({ index, forms, setForms, skipLogicCondition, setDisplayLogicQuestionSelecteData, displaylogicQuestionSelecteData, setDisplayLogicCondition,
  displayLogicCondition,
  setSkipLogicCondition, getPayload, formIndex, skipLogicData, logicQuestionSelecteData, setLogicQuestionSelecteData, }) => {
  const handleChange = (event) => {
    let newArray = [...forms];
    let ids = uuidv4();
    newArray[index] = {
      ...getPayload(event.target.value),
      type: event.target.value,
      index: index,
      logicQuestionType: event.target.value == "text" ? "textEmail" : event.target.value,
      ids: ids
    };
    setForms(newArray);
    let newArray1 = [...logicQuestionSelecteData];
    newArray1[index] = {
      ...getPayload(event.target.value),
      type: event.target.value,
      index: index,
      ids: ids,
      name: forms[index].question,
      value: "",
    };
    setLogicQuestionSelecteData(newArray1);

    let newArray2 = [...displaylogicQuestionSelecteData];
    newArray2[index] = {
      type: event.target.value,
      index: index,
      ids: ids,
      name: forms[index].question,
      value: "",
    };
    setDisplayLogicQuestionSelecteData(newArray2);

  };
  //////////
  const [value, setValue] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };
  const [anchorElAdd, setAnchorElAdd] = React.useState(null);
  const [anchorElRemove, setAnchorElRemove] = React.useState(null);

  const openAdd = Boolean(anchorElAdd);
  const openRemove = Boolean(anchorElRemove);
  const idAdd = openAdd ? "add-popover" : undefined;
  const idRemove = openRemove ? "remove-popover" : undefined;

  const handleCloseRemove = () => {
    setAnchorElRemove(null);
  };

  return (
    <>
      <Stack
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          flexDirection: "row",
          width: "100%",
          marginBottom: "20px",
        }}
      >
        <Stack sx={{ width: "100%" }}>
          <Stack
            sx={{
              backgroundColor: "white.main",
              borderRadius: "7px",
              padding: "20px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              height: "fit-content",
            }}
          >
            <Stack sx={{ display: "flex", flexDirection: "row", gap: "20px" }}>
              <Typography
                fontSize={{ xs: "10px", sm: "16px" }}
                fontWeight={500}
                mb={"2px"}
                width={"10px"}
                display={"flex"}
                flexDirection={"column"}
              >
                {index + 1}.
              </Typography>
              <Stack
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <textarea
                  placeholder="Please Enter your Question text here..."
                  className="defaultinput textareaformcreation"
                  onChange={(e) => {
                    let newArray = [...forms];
                    newArray[index].question = e.target.value;
                    setForms(newArray);
                  }}
                  value={forms[index].question}
                />

                <Stack
                  sx={{ display: "flex", flexDirection: "column" }}
                  width={"100%"}
                >
                  <Input
                    placeholder="Instruction Text"
                    onChange={(e) => {
                      let newArray = [...forms];
                      newArray[index].intructinText = e.target.value;
                      setForms(newArray);
                    }}
                    value={forms[index].intructinText}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
              className="formcard"
            >
              {/* header */}
              <Stack className="formcreationmain">
                <Box className="headerrow">
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <FormControl>
                      <Select
                        value={forms[index].type}
                        onChange={handleChange}
                        displayEmpty
                        sx={{
                          width: 300,
                          ".MuiSelect-select": {
                            padding: "10px",
                          },
                        }}
                      >
                        <MenuItem
                          value=""
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          Select Question Type
                        </MenuItem>
                        <MenuItem
                          value="text"
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          Text
                        </MenuItem>
                        <MenuItem
                          value="multiSelect"
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          {" "}
                          Multiple Choice
                        </MenuItem>
                        <MenuItem
                          value="scale"
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          {" "}
                          Scale
                        </MenuItem>
                        <MenuItem
                          value="DropDown"
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          {" "}
                          Drop Down
                        </MenuItem>
                        <MenuItem
                          value="Metrix"
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          {" "}
                          Metrix
                        </MenuItem>
                        <MenuItem
                          value="Scan"
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          Media Capture
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <Box sx={{ width: "fit-content" }}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={value}
                          onChange={handleChange1}
                          aria-label="basic tabs example"
                        >
                          <Tab label="Options" {...a11yProps(0)} />
                          <Tab label="Logic" {...a11yProps(1)} />
                        </Tabs>
                      </Box>
                    </Box>
                  </Stack>
                  <label style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      style={{
                        marginRight: "10px",
                        height: "17px",
                        width: "17px",
                      }}
                      onChange={(e) => {
                        let newArray = [...forms];
                        newArray[formIndex].answerRequired = e.target.checked;
                        setForms(newArray);
                      }}
                      checked={forms[formIndex].answerRequired}
                    />
                    Answer Required
                  </label>
                </Box>
              </Stack>
              {/* body */}
              <div className="verticalline"></div>
              {/* body */}
              <CustomTabPanel value={value} index={0}>
                <Stack spacing={1}>
                  <Stack
                    sx={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: "col",
                    }}
                  >
                    <Stack mt={2}>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="dynamic"
                          name="radio-buttons-group"
                          row
                        >
                          <FormControlLabel
                            value="dynamic"
                            control={<Radio />}
                            label="Dynamic Row"
                            onChange={(e) => {
                              let newArray = [...forms];
                              newArray[formIndex].rowType = e.target.value;
                              newArray[formIndex].metrixData = [
                                [
                                  {
                                    type: "text",
                                    value: "",
                                    dataArray: [],
                                    disable: true,
                                  },
                                  {
                                    type: "text",
                                    value: "",
                                    dataArray: [],
                                    disable: true,
                                  },
                                ],
                              ];
                              setForms(newArray);
                            }}
                            checked={forms[formIndex].rowType === "dynamic"}
                          />
                          <FormControlLabel
                            value="fixed"
                            control={<Radio />}
                            label="Fixed Row"
                            onChange={(e) => {
                              let newArray = [...forms];
                              newArray[formIndex].rowType = e.target.value;
                              newArray[formIndex].metrixData = [
                                [
                                  { type: "text", value: "", disable: true, dataArray: [] },
                                  { type: "text", value: "", disable: true, dataArray: [] },
                                ],
                                [
                                  { type: "text", value: "", disable: true, dataArray: [] },
                                  { type: "text", value: "", disable: false, dataArray: [] },
                                ],
                              ];
                              setForms(newArray);
                            }}
                            checked={forms[formIndex].rowType === "fixed"}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Stack>
                  </Stack>
                  {forms[formIndex].rowType === "dynamic" ? (
                    <MetrixTypeForm
                      index={index}
                      forms={forms}
                      setForms={setForms}
                      formIndex={formIndex}
                    />
                  ) : (
                    <MetrixRowFixType
                      index={index}
                      forms={forms}
                      setForms={setForms}
                      formIndex={formIndex}
                    />
                  )}
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      gap: "20px",
                      flexDirection: "row",
                      marginTop: "10px",
                    }}
                  ></Stack>
                </Stack>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Stack spacing={2}>

                  <SkipLogic logicQuestionSelecteData={logicQuestionSelecteData} forms={forms} setForms={setForms} index={index} setLogicQuestionSelecteData={setLogicQuestionSelecteData} skipLogicData={skipLogicData} skipLogicCondition={skipLogicCondition} setSkipLogicCondition={setSkipLogicCondition} displayLogicCondition={displayLogicCondition} setDisplayLogicCondition={setDisplayLogicCondition} displaylogicQuestionSelecteData={displaylogicQuestionSelecteData} setDisplayLogicQuestionSelecteData={setDisplayLogicQuestionSelecteData}
                  />

                </Stack>
              </CustomTabPanel>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default MatrixMain;

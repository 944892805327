import React from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, Input, Typography } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons'
import { Button } from '..'
import CommonInput from '../Input'
const ExtendForm = ({ handleClose, modalOpen, handelClick, formik }) => {
    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {modalOpen.head}
            </DialogTitle>
            <IconButton aria-label="close" onClick={handleClose}
                sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Box width={{ sm: '400px' }} display={"flex"} alignItems={"center"} gap={2}>
                    <Typography fontSize={'18px'}>Select Date:</Typography>
                    <CommonInput type='date' name='extendDate' formik={formik} isDate={true} min={new Date().toISOString().split('T')[0]} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button buttonName={'Cancle'} size='small' color='white' onClick={handleClose} />
                <Button buttonName={'Save'} size='small' onClick={formik.handleSubmit} />
            </DialogActions>
        </>
    )
}

export default ExtendForm
import React from "react";
import {
  Drawer,
  Grid,
  Typography,
  Divider,
  Stack,
  IconButton,
  Slider,
  Box,
} from "@mui/material";
import { CloseIcon } from "../../helper/Icons";
import { Button, Input } from "../../components/Common";
import CommonInput from "../../components/Common/Input";
import CommonSelect from "../../components/Common/Select";

const FilterSubmitform = ({
  openDrawer,
  formik,
  value,
  setValue,
  toggleDrawer,
  auditordata,
}) => {
  const drawerWidth = 250;

  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            padding: 2,
          },
        }}
      >
        <Box height={"100%"}>
          <Grid container>
            <Grid item xs={12}>
              <Typography fontWeight={600} fontSize={"18px"}>
                {"Filter"}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={toggleDrawer}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon sx={{ width: "20px", height: "20px" }} />
              </IconButton>
            </Grid>
            <Grid item xs={12} marginY={"5px"}>
              <Divider sx={{ borderColor: "gray.main" }} />
            </Grid>

            <Grid item xs={12} marginY={2}>
              <Grid container gap={2}>
                <Grid item xs={12}>
                  <CommonInput
                    labelinput={"From Submit Date"}
                    name="fromSubmitDate"
                    type="date"
                    isDate={true}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    labelinput={"To Submit Date"}
                    name="toSubmitDate"
                    type="date"
                    formik={formik}
                    isDate={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CommonSelect
                    labelinput={"Auditor"}
                    name="auditor"
                    selectList={auditordata}
                    formik={formik}
                    selectHead={"Select Auditor"}
                    onChange={(e) => {
                      formik.setFieldValue("auditor", e.target.value);
                    }}
                    value={formik.values.auditor}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Stack flexDirection={"row"} gap={1}>
          <Button
            buttonName={"Clear"}
            color="white"
            style={{ width: "100%" }}
            size="small"
            onClick={() => {
              // resetForm();
              setValue([]);
              toggleDrawer();
              formik.resetForm();
              formik.handleSubmit();
              // handleSubmit();
            }}
          />
          <Button
            buttonName={"Filter"}
            style={{ width: "100%" }}
            size="small"
            onClick={() => {
              // handleSubmit();
              toggleDrawer();
              formik.handleSubmit();
            }}
          />
        </Stack>
      </Drawer>
    </>
  );
};

export default FilterSubmitform;

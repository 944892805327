import { deleteRequest, get, post, put } from "../web.request";

export const formAdd = async (data) => {
  return await post(`/createSurveyForm`, data);
}

export const surveyFormList = async (qry) => {
  return await get(`/surveyFormList${qry}`);
}

export const formStatusChange = async (id, data) => {
  return await put(`/statusChangeForm/${id}`, data);
}

export const formDelete = async (id) => {
  return await deleteRequest(`/deleteForm/${id}`);
}

export const formDetail = async (id) => {
  return await get(`/surveyFormView/${id}`);
}

export const formUpdate = async (id, data) => {
  return await put(`/updateSurveyForm/${id}`, data);
}

export const formAssign = async (id, data) => {
  return await put(`/formAssign/${id}`, data);
}

export const extendForm = async (id, data) => {
  return await put(`/extendForm/${id}`, data);
}

//copy form
export const copyForm = async (id) => {
  return await get(`/copySurveyForm/${id}`);
}

export const exportForm = async (id) => {
  return await post(`/submittedFormDownload/${id}`);
}
import {
  Box,
  Grid,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { BackButton, Button, Heading, Modal } from "../../components/Common";
import { IoIosArrowDown } from "react-icons/io";
import { useLocation } from 'react-router-dom';
import { AdminChnagePasswordOnMail, AuditorCreate, AuditorDetail, AuditorUpdate, DeviceDelete } from "../../Service/Auditor.service";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { useDispatch } from 'react-redux';
import { useFormik } from "formik";
import { addAuditorInitialValues } from "../../helper/initialValues";
import { addAdminValidationSchema, addAuditorValidationSchema } from "../../helper/validation";

const AuditorsDetails = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const [auditorData, setAuditorData] = useState({});

  useEffect(() => {
    if (state) {
      fetchAuditorData(state);
    }
  }, [state]);

  const formik = useFormik({
    initialValues: addAuditorInitialValues,
    onSubmit: async (value) => {

      const payload = {
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email,
        mobile: value.mobile,
        location: value.location
      }

      let res = await AuditorUpdate(state, payload);
      if (res?.status) {
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'Success'
        }))
        setIsModalOpen({ open: false, currentComponent: "", para: '', head: '', isEdit: false, isView: false });
        fetchAuditorData(state);
        formik.resetForm();
      } else {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong. Please try again later.'
        }
        ))
      }

    },
    validationSchema: addAuditorValidationSchema,
  });
  const fetchAuditorData = async (id) => {
    try {
      const res = await AuditorDetail(id);
      if (res?.status) {
        setAuditorData(res?.data);
      } else {
        setAuditorData({});
      }
    } catch (error) {
      console.error("Error fetching auditor data:", error);
    }
  };

  const changeStatus = async () => {
    let res = isModalOpen && isModalOpen?.currentComponent == "delete" ? await DeviceDelete(isModalOpen?.id) : isModalOpen && isModalOpen?.currentComponent == "changepassword" ? await AdminChnagePasswordOnMail(isModalOpen?.id) : null;

    if (res?.status) {
      dispatch(notificationSuccess({
        show: true,
        type: 'success',
        message: res.message || 'Success'
      }))
      fetchAuditorData(state);
      setIsModalOpen({ open: false, currentComponent: "", para: '', head: '', id: '' });
    } else {
      dispatch(notificationSuccess({
        show: true,
        type: 'error',
        message: res.message || 'Something went wrong. Please try again later.'
      }
      ))
    }
  }

  return (
    <>
      <Heading head={"Auditor Details"} />
      <Stack gap={2} mt={1}>
        <Stack
          mb={1}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack
            flexDirection={"row"}
            justifyContent={"flex-start"}
            gap={1}
            alignItems={"center"}
          >
            <BackButton />
          </Stack>
          <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
            <Button buttonName={"Edit"} onClick={() => {
              formik.setFieldValue('firstName', auditorData?.firstName);
              formik.setFieldValue('lastName', auditorData?.lastName);
              formik.setFieldValue('email', auditorData?.email);
              formik.setFieldValue('mobile', auditorData?.mobile);
              formik.setFieldValue('location', auditorData?.location);
              setIsModalOpen({
                open: true,
                currentComponent: "addauditor",
                id: state,
                isEdit: true
              });
            }}
              size="small" />
            <Button
              buttonName={"Change Password"}
              style={{ padding: "10px 11px" }}
              color="white"
              size="small"
              variant="contained"
              onClick={() => {
                setIsModalOpen({
                  open: true,
                  currentComponent: "changepassword",
                  head: "Change Password",
                  para: "Are You Sure to Change Password?",
                  id: state
                });
              }}
              sx={{ mt: 2 }}
            />
          </Stack>
        </Stack>
        <Stack
          gap={2}
          sx={{
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "stretch", sm: "flex-start" },
            height: "100%",
          }}
        >
          <Grid sx={{ display: "flex", flexDirection: "column" }} gap={2}>
            <Box
              className="auditorinfo_card"
              sx={{
                flex: 1,
                mb: { xs: 2, sm: 0 },
              }}
            >
              <>
                <Typography sx={{ fontWeight: 600, mb: 1 }}>
                  Auditor Information
                </Typography>

                <Typography>
                  <span>Name:</span> {auditorData.firstName} {auditorData.lastName}
                </Typography>
                <Typography>
                  <span>Mobile Number:</span> {auditorData.mobile}
                </Typography>
                <Typography>
                  <span>Email:</span> {auditorData.email}
                </Typography>
                <Typography>
                  <span>Location:</span> {auditorData.location}
                </Typography>
              </>
            </Box>
            <Grid sx={{ display: "flex" }} gap={2}>
              <Box className="active_survey" sx={{ flex: 1 }}>
                <Typography sx={{ display: "flex", flexDirection: "column" }}>
                  <span>Active Survey</span> {auditorData.activeSurvey || 1}
                </Typography>
              </Box>
              <Box className="active_survey" sx={{ flex: 1 }}>
                <Typography sx={{ display: "flex", flexDirection: "column" }}>
                  <span>Num. of Survey</span> {auditorData.numOfSurvey || 5}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Stack
            gap={2}
            mt={1}
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: { xs: "stretch", sm: "flex-start" },
              flexWrap: "wrap",
            }}
          >
            {auditorData && auditorData.devices ? (
              auditorData.devices.map((item, index) => (
                <Box className="auditorDetail_card" sx={{ flex: 1 }} key={index} gap={2}>
                  <Typography>
                    <span>Device Name:</span> {item.deviceName}
                  </Typography>
                  <Typography>
                    <span>Device-Id:</span> {item.deviceId}
                  </Typography>
                  <Typography>
                    <span>Device Version:</span> {item.deviceVersion}
                  </Typography>
                  <Typography>
                    <span>Model:</span> {item.model}
                  </Typography>
                  <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "2px" }}>
                    <Button
                      buttonName={"Delete"}
                      style={{ padding: "10px 11px" }}
                      onClick={() => {
                        setIsModalOpen({
                          open: true,
                          currentComponent: "delete",
                          para: "Are You Sure to Delete Information?",
                          id: item._id
                        });
                      }}
                    />
                  </div>
                </Box>
              ))
            ) : (
              <Typography>No devices found.</Typography>
            )}
          </Stack>
        </Stack>
        <Stack>
          <Heading smallHead={"Device Review"} />
          <TableContainer>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ cursor: "pointer" }}>
                    <Stack flexDirection={"row"} gap={2} alignItems={"center"} justifyContent={"start"}>
                      <div>{"Device-id"}</div>
                      <IoIosArrowDown />
                    </Stack>
                  </TableCell>
                  <TableCell style={{ cursor: "pointer" }}>
                    <Stack flexDirection={"row"} gap={2} alignItems={"center"} justifyContent={"start"}>
                      <div>{"Device-Name"}</div>
                      <IoIosArrowDown />
                    </Stack>
                  </TableCell>
                  <TableCell style={{ cursor: "pointer" }}>
                    <Stack flexDirection={"row"} gap={2} alignItems={"center"} justifyContent={"start"}>
                      <div>{"Model"}</div>
                      <IoIosArrowDown />
                    </Stack>
                  </TableCell>
                  <TableCell style={{ cursor: "pointer" }}>
                    <Stack flexDirection={"row"} gap={2} alignItems={"center"} justifyContent={"start"}>
                      <div>{"Review"}</div>
                      <IoIosArrowDown />
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableHead>
              {auditorData && Array.isArray(auditorData.devices) && auditorData.devices.length > 0 ? (
                auditorData.devices.map((item, index) => (
                  <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell className="table-hover" style={{ textAlign: "left" }}>
                      {item.deviceId}
                    </TableCell>
                    <TableCell className="table-hover" style={{ textAlign: "left" }}>
                      {item.deviceName}
                    </TableCell>
                    <TableCell className="table-hover" style={{ textAlign: "left" }}>
                      {item.model}
                    </TableCell>
                    <TableCell className="table-hover" style={{ textAlign: "left" }}>
                      {item.deviceVersion}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <Typography>No devices found.</Typography>
              )}
            </Table>
          </TableContainer>
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "5px" }}>
            <Button buttonName={"View All"} color="white" style={{ padding: "10px 11px" }} />
          </div>
        </Stack>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        formik={formik}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "", id: "" })
          formik.resetForm()
        }}
        handelClick={changeStatus}
      />
    </>
  );
};

export default AuditorsDetails;

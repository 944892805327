import React, { useEffect } from 'react'
import { submittedFormDetail } from '../../Service/submittedForm'
import { useLocation } from 'react-router-dom';
import TextFormView from './FormView/TextFormView';
import { Divider, Stack } from '@mui/material';
import MultiSelectView from './FormView/MultiSelectView';
import MetrixView from './FormView/MetrixView';
import CommonView from './FormView/CommonView';
import DropdownView from './FormView/DropdownView';
import ScaleView from './FormView/ScaleView';
import MediaCaptureView from './FormView/MediaCaptureView';

const SubmittedFormView = () => {
  const [submittedForm, setSubmittedForm] = React.useState({});
  const [formData, setFormData] = React.useState([]);
  console.log("formData", formData);

  const { state } = useLocation();

  useEffect(() => {
    if (state && state.id) {
      getSubmittedForm(state?.id);
    }
  }, [state]);

  const getSubmittedForm = async (id) => {
    const res = await submittedFormDetail(id);
    if (res.status) {
      setSubmittedForm(res.data);
      setFormData(res?.data?.submittedData);
    }
    else {
      setSubmittedForm({});
    }
  }

  console.log("submittedForm", submittedForm);
  const getFormComponent = (item, index) => {
    switch (item.type) {
      case "text":
        return <TextFormView item={item} index={index} />
      case "scale":
        return <ScaleView item={item} index={index} />
      case "multiSelect":
        return <MultiSelectView item={item} index={index} />
      case "Metrix":
        return <MetrixView item={item} index={index} />
      case "DropDown":
        return <DropdownView item={item} index={index} />
      case "Scan":
        return <MediaCaptureView item={item} index={index} />
      default:
        return null;
    }
  }


  return (
    <Stack style={{ border: '1px solid black', padding: "20px", borderRadius: "10px", height: "cal(100%-30px)" }} spacing={3}>
      <CommonView submittedForm={submittedForm} />
      <div className="verticalline"></div>
      {formData.map((item, index) => {
        return (
          <Stack key={index}>
            {getFormComponent(item, index + 1)}
          </Stack>
        )
      })}
      {/* 
      <TextFormView />
      <div className="verticalline"></div>


      <ScaleView />
      <div className="verticalline"></div>


      <MultiSelectView />
      <div className="verticalline"></div>


      <MetrixView />
      <div className="verticalline"></div>


      <DropdownView />
      <div className="verticalline"></div>

      <MediaCaptureView /> */}
    </Stack>
  )
}

export default SubmittedFormView
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#DA2921',
    },
    secondary: {
      main: '#123',
    },
    black: {
      main: '#000000',
    },
    white: {
      main: '#FFFFFF',
    },
    gray: {
      main: '#4A4A4A',
    },
    border: {
      main: '#D4C3C4',
    },
    red: {
      main: '#FF5636'
    },
    green: {
      main: '#00AD6F'
    },
    orange:{
      main:'#FFA500'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          textTransform: 'capitalize !important',
          fontSize: '12px !important',
          lineHeight: '1 !important',
          fontWeight: '500',
          color: '#FFF',
          padding: '10px 20px',
          width: 'fit-content',
          minWidth: 'unset',
          whiteSpace: 'nowrap',
          boxShadow: 'unset',
          '@media (max-width: 600px)': {
            fontSize: '14px !important',
            padding: '12px 14px',
          },
          '& .MuiButton-startIcon svg': {
            '@media (max-width: 600px)': {
              fontSize: '19px !important',
            },
          }
        },
        containedWhite: {
          color: 'rgba(0, 0, 0)',
          boxShadow: 'unset !important',
          border: '1px solid rgba(0, 0, 0, 0.12)',
        },
        sizeSmall: {
          padding: '10px 20px',
          fontSize: '14px !important',
        },
        containedGreen: {
          padding: '10px 20px',
        },
        icon: {
          color: '#000',
        }

      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '7px',
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            fontSize: '13px',
            fontWeight: '400',
          },
          '& .MuiOutlinedInput-root': {
            input: {
              fontSize: '13px',
              fontWeight: '400',
              borderRadius: '6px',
              padding: '10px 15px',
              color: '#000',
              background: '#fff',
              '@media (max-width: 600px)': {
                fontSize: '10px !important',
                // padding: '12px 13px',
              },
            },
            fieldset: {
              border: '1px solid rgba(0, 0, 0, 0.12)',
              borderRadius: '6px',
            }
          },
          '& .MuiOutlinedInput-input::placeholder': {
            color: '#747474',
          },
          '& .MuiInputBase-adornedStart ': {
            paddingLeft: '10px',
            input: {
              padding: '9px 14px 9px 0',
            },
          }
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        },
        notchedOutline: {
          border: '1px solid rgba(0, 0, 0, 0.12)'
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          padding: '5px 15px !important',
        },
        popper: {
          zIndex: 99999,
        },
        inputRoot: {
          padding: '5px',
        },
        root: {
          '& .MuiInputBase-input': {
            boxShadow: 'unset !important',
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
        },
        select: {
          fontSize: '13px',
          fontWeight: '400',
          padding: '8px 15px',
          minHeight: 'unset',
          color: '#000',
          background: '#fff',
          '@media (max-width: 600px)': {
            fontSize: '14px !important',
            padding: '10px 15px',
          },
          fieldset: {
            border: '1px solid rgba(0, 0, 0, 0.12)'
          },
          '& .MuiInputBase-root:hover': {
          },
          '& .MuiInputBase-input::placeholder': {
            color: '#000',
          },
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          '@media (max-width: 600px)': {
            fontSize: '14px !important',
            minHeight: '35px !important',
          },
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          zIndex: 99999,
        }
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: 'unset',
          borderRadius: '6px',
          border: '1px solid rgba(0, 0, 0, 0.12)'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          border: '1px solid rgba(0, 0, 0, 0.12)'
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '& .MuiTableHead-root': {
            '& .MuiTableCell-root': {
              textTransform: 'capitalize',
              color: '#000',
              background: '#66666610'
            }
          },
          '& .MuiReportTable': {
            textAlign: 'start !important',
            '&:first-of-type': {
              textAlign: 'start !important',
            },
            '&:last-child': {
              textAlign: 'start !important',
            },
          },
          '& .history_table': {
            textAlign: 'start !important',
            '&:first-of-type': {
              textAlign: 'start !important',
            },
            '&:last-child': {
              textAlign: 'start !important',
            },
          },
          '& .MuiTableCell-root': {
            padding: '10px 16px',
            fontSize: '14px',
            whiteSpace: 'nowrap',
            color: '#0F0F0F',
            textAlign: 'center',
            // '&:nth-of-type(2)': {
            //   textAlign: 'start !important',
            // },
            '&:first-of-type': {
              textAlign: 'start',
            },
            '&:last-child': {
              textAlign: 'end',
            },
          },
          // Media query for smaller screens
          '@media (max-width: 600px)': {
            '& .MuiTableCell-root': {
              fontSize: '14px',
              padding: '10px 10px',

            },
          },
          '& .MuiTableBody-root': {
            '& .MuiTableRow-root:hover': {
              boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
              background: '#DA292110'
            },
          },
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '15px !important',
          height: '24px',
          fontSize: '12px',
          '@media (max-width: 600px)': {
            height: '24px',
            fontSize: '14px',
          },
          '& .MuiSvgIcon-root ': {
            fontSize: '16px',
          }
        }
      }
    },
    // table -----------
    // modal -----------
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '18px !important',
          fontWeight: '600',
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '& .MuiTypography-root': {  
            fontSize: '16px !important'
          }
        }
      }
    },
    // modal -----------
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root': {
            minHeight: '40px',
            textTransform: 'capitalize',
            alignItems: 'flex-start'
          },
          '& .Mui-selected ': {
            background: 'transparent',
          },
          '& .MuiTabs-indicator': {
            left: 0,
            right: 'unset',
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: '9999',
        }
      }
    },
    // MuiDialogTitle: {
    //   styleOverrides: {
    //     root: {
    //       fontSize: '16px'
    //     }
    //   }
    // },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '16px'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h6: {
          fontSize: '20px !important'
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          marginBottom: 0,
        }
      }
    },
  },
});

// export const MyComponent = styled('div')({
//   borderRadius: '0px',
//   background: '#151515',
//   boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important',
// });

export const getEducatorsStatus = (value) => {
  const status = {
    false: {
      title: "Absent",
      styles: {
        backgroundColor: "#FF563625",
        color: "#FF5636",
      },
    },
    true: {
      title: "Present ",
      styles: {
        backgroundColor: "rgba(18, 183, 106, 0.20)",
        color: "#00AD6F",
      },
    },

  };
  return status[value];
};

export const getAlertStatus = (value) => {
  const status = {
    false: {
      title: "Off",
      styles: {
        backgroundColor: "#FF563625",
        color: "#FF5636",
      },
    },
    true: {
      title: "On",
      styles: {
        backgroundColor: "rgba(18, 183, 106, 0.20)",
        color: "#00AD6F",
      },
    },

  };
  return status[value];
};
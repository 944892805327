import React from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons";
import { Button, Input } from "..";

const EditFormInformation = ({ handleClose, modalOpen }) => {
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {"Edit Details"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container gap={2} maxWidth={{ sm: "400px" }} paddingY={1}>
          <Grid item xs={12}>
            <Input
              labelinput={"Name"}
              // passwordinput
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              labelinput={"Created"}
              // passwordinput
              name="created"
              type="date"
            />
          </Grid>
          <Grid item xs={12}>
            <Input labelinput={"Published"} name="published" type="date" />
          </Grid>
          <Grid item xs={12}>
            <Input
              labelinput={"Expires"}
              // passwordinput
              name="expire"
              type="date"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName={"Cancel"}
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button buttonName={"Save"} size="small" onClick={handleClose} />
      </DialogActions>
    </>
  );
};

export default EditFormInformation;

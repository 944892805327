import {
  Box,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Rating,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import { Button, Input } from "../../../components/Common";
import { IoIosAdd } from "react-icons/io";
import { IoRemoveOutline } from "react-icons/io5";
import { HiOutlineTrash } from "react-icons/hi";
import { v4 as uuidv4 } from "uuid";
import Papa from "papaparse";

const MetrixRowFixType = ({
  index,
  forms,
  setForms,
  formIndex,
}) => {

  const [formType, setFormType] = React.useState("text");
  const fileInputRef = useRef([]);
  const handleTypeChange = (event) => {
    let newArray = [...forms];
    newArray[index].metrixData = newArray[index].metrixData.map((row, rIndex) =>
      row.map((cell, cIndex) =>
        rIndex != 0 && cIndex != 0 ? { ...cell, type: event.target.value } : cell
      )
    );
    newArray[index].metrixDataArray = [];
    newArray[index].fixRowSelectBoxType = event.target.value;
    setForms(newArray);
    setFormType(event.target.value);
  };

  const randomId = () => {
    return uuidv4();
  };


  const [anchorElAdd, setAnchorElAdd] = React.useState(null);
  const [anchorElRemove, setAnchorElRemove] = React.useState(null);

  const handleClickAdd = (event) => {
    setAnchorElAdd(event.currentTarget);
  };

  const handleClickRemove = (event) => {
    setAnchorElRemove(event.currentTarget);
  };

  const handleCloseAdd = () => {
    setAnchorElAdd(null);
  };

  const handleCloseRemove = () => {
    setAnchorElRemove(null);
  };

  const openAdd = Boolean(anchorElAdd);
  const openRemove = Boolean(anchorElRemove);
  const idAdd = openAdd ? "add-popover" : undefined;
  const idRemove = openRemove ? "remove-popover" : undefined;

  const addRow = () => {
    let newArray = [...forms];
    let newRow = [];
    newArray[index].metrixData[0].map((row, index) => newRow.push(
      index == 0 ? { type: "text", value: "", disable: true } : { type: formType, value: "", disable: false }
    ));
    newArray[index].metrixData.push(newRow);
    setForms(newArray);
  };

  const addColumn = () => {
    let newArray = [...forms];
    newArray[formIndex].metrixData.map((row, index) => row.push(
      index == 0 ? { type: "text", value: "", disable: true, dataArray: [] } : {
        type: formType, value: "", disable: false, dataArray: []
      }
    ));
    setForms(newArray);
  };

  console.log("newArray", forms);


  const removeRow = () => {
    if (forms[index].metrixData.length > 1) {
      let newArray = [...forms];
      newArray[index].metrixData.pop();
      setForms(newArray);
    }
  };

  const removeColumn = () => {
    if (forms[index].metrixData[0].length > 1) {
      let newArray = [...forms];
      newArray[index].metrixData.map((row) => row.pop());
      setForms(newArray);
    }
  };

  const handleInputChange = (rowIndex, colIndex, value) => {

    const updatedTable = forms[index].metrixData.map((row, rIndex) =>
      row.map((cell, cIndex) =>
        rIndex === rowIndex && cIndex === colIndex ? { ...cell, value: value } : cell
      )
    );
    let newArray = [...forms];
    newArray[index].metrixData = updatedTable;
    setForms(newArray);

  };
  const columnType = (type) => {
    switch (type) {
      case "single":
        return <input type="radio" id="radio" />;
      case "multiple":
        return <input type="checkbox" />;
      case "text":
        return <input type="text" style={{
          width: "100%",
          padding: "5px",
          borderRadius: "5px",
          height: "100%",
          borderColor: "#F5F5F5",
        }} />;
      case "ratting":
        return <Rating />;
      case "selectbox":
        return <Select
          style={{
            width: "100%",
            padding: "5px",
            borderRadius: "5px",
            height: "100%",
            borderColor: "#F5F5F5",
          }}
          value="1"
        >
          <MenuItem value="1">Select Answer</MenuItem>
        </Select>



      default:
        return null;
    }
  };

  // const handleAddRating = (indexs) => {
  //   let newArray = [...forms];

  //   // Ensure dataArray is initialized as an array
  //   if (!Array.isArray(newArray[index].metrixData[0][indexs].dataArray)) {
  //     newArray[index].metrixData[indexs].dataArray = [];
  //   }

  //   console.log(
  //     "newArray[index].metrixData[indexs].dataArray",
  //     newArray[index].metrixData[0][indexs].dataArray
  //   );

  //   // Add a new object to dataArray
  //   newArray[index].metrixData[0][indexs].dataArray = [
  //     ...newArray[index].metrixData[0][indexs].dataArray,
  //     { ids: randomId(), value: "", disable: true },
  //   ];

  //   setForms(newArray);
  // };

  const handleAddRating = (indexs) => {
    // Create a copy of forms
    let newArray = [...forms];

    // Ensure that index and indexs are valid and that the necessary properties exist
    if (newArray[index] && newArray[index].metrixData && newArray[index].metrixData[0] && newArray[index].metrixData[0][indexs]) {
      // Ensure dataArray is initialized as an array
      if (!Array.isArray(newArray[index].metrixData[0][indexs].dataArray)) {
        newArray[index].metrixData[0][indexs].dataArray = [];
      }

      console.log(
        "newArray[index].metrixData[0][indexs].dataArray",
        newArray[index].metrixData[0][indexs].dataArray
      );

      // Add a new object to dataArray
      newArray[index].metrixData[0][indexs].dataArray = [
        ...newArray[index].metrixData[0][indexs].dataArray,
        { ids: randomId(), value: "", disable: true },
      ];

      setForms(newArray);
    } else {
      console.error("Invalid index or metrixData structure");
    }
  };



  const handleDeleteRating = (id, i) => {
    let newArray = [...forms];
    newArray[index].metrixData[0][i].dataArray = newArray[index].metrixData[0][
      i
    ].dataArray.filter((rating) => rating.ids !== id);
    if (newArray[index].metrixData[0][i].dataArray.length === 0) {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      newArray[index].metrixData[0][i].dataArray.file = "";
    }
    setForms(newArray);
  };
   const handleRating = (id, value, index, indexs) => {
    // Create a copy of the forms array
    let newArray = [...forms];

    console.log("newArraysdadsdsdsd", newArray[formIndex]);


    // Ensure that the index exists in newArray
    if (newArray[formIndex] && newArray[formIndex].metrixData && newArray[formIndex].metrixData[0][index]) {
      // Check if dataArray exists at the expected location
      if (newArray[formIndex].metrixData[0][index].dataArray) {
        newArray[formIndex].metrixData[0][index].dataArray = newArray[formIndex].metrixData[0][index].dataArray.map((rating) =>
          rating.ids === id ? { ...rating, value } : rating
        );

        setForms(newArray);
      } else {
        console.error('dataArray is undefined at the given indexs');
      }
    } else {
      console.error('Invalid index or data path does not exist');
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    Papa.parse(file, {
      complete: (results) => {
        const [header, ...data] = results.data;
        let newArray = [...forms];

        console.log("headerheader", header);

        // Iterate over each column header from the file
        header.forEach((fileHeader, fileIndex) => {
          const fileHeaderTrimmed = fileHeader.trim();
          const columnIndex = forms[index].metrixData[0].findIndex(
            (column) => column.value === fileHeaderTrimmed
          );


          if (columnIndex !== -1) {
            // Column header matched
            const parsedData = data.map((row) => ({
              ids: uuidv4(),
              value: row[fileIndex] ? row[fileIndex].trim() : "",
              file: true,
            }));
            console.log("parsedData", parsedData, newArray[index].metrixData[0][columnIndex].dataArray)
            if (newArray[index].metrixData[0][columnIndex].dataArray) {
              newArray[index].metrixData[0][columnIndex].dataArray = parsedData;
            } else {
              if (!Array.isArray(newArray[index].metrixData[0][columnIndex].dataArray)) {
              } else {
                newArray[index].metrixData[0][columnIndex].dataArray = [
                  ...newArray[index].metrixData[0][columnIndex].dataArray,
                  ...parsedData
                ];
              }
            }
          } else {
            console.error(
              `Header ${fileHeaderTrimmed} does not match any existing column`
            );
          }
        });
        setForms(newArray);
      },
      header: false,
    });

    let newArray = [...forms];
    newArray[formIndex].file = file.name
    setForms(newArray);
  };

  const handleFileRemove = (i) => {
    let newArray = [...forms];
    newArray[index].file = "";

    if (newArray?.[index]?.metrixData?.[0]) {
      newArray[index].metrixData[0].forEach((row, rowIndex) => {
        if (row.dataArray) {
          newArray[index].metrixData[0][rowIndex].dataArray = row.dataArray.filter(
            (answer) => !answer.file
          );
        }
      });
    }
    setForms(newArray);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  return (
    <>
      <Stack
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          flexDirection: "row",
          width: "100%",
          marginBottom: "20px",
        }}
      >
        <Stack sx={{ width: "100%" }}>

          <Stack
            sx={{
              backgroundColor: "white.main",
              borderRadius: "7px",
              padding: "20px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              height: "fit-content",
            }}
          >


            <Stack
              sx={{
                display: "flex",
                gap: "10px",
                flexDirection: "col",
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  flexDirection: "row",
                }}
              >
                <div>
                  <IconButton
                    aria-describedby={idAdd}
                    variant="contained"
                    onClick={handleClickAdd}
                  >
                    <IoIosAdd
                      size={23}
                      style={{
                        backgroundColor: "green",
                        padding: "2px",
                        color: "white",
                        borderRadius: "50%",
                      }}
                    />
                  </IconButton>
                  <Popover
                    id={idAdd}
                    open={openAdd}
                    anchorEl={anchorElAdd}
                    onClose={handleCloseAdd}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Stack>
                      <Button
                        buttonName={"Add Row"}
                        color="white"
                        style={{ width: "100%", border: "none" }}
                        onClick={addRow}
                      />
                      <Button
                        buttonName={"Add Column"}
                        color="white"
                        style={{ width: "100%", border: "none" }}
                        onClick={addColumn}
                      />
                    </Stack>
                  </Popover>
                </div>
                {(forms && forms[index] && forms[index] && forms[index].metrixData && forms[index].metrixData[0].length > 2 || forms && forms[index] && forms[index] && forms[index].metrixData && forms[index].metrixData.length > 2) &&
                  < div >
                    <IconButton
                      aria-describedby={idRemove}
                      variant="contained"
                      onClick={handleClickRemove}
                    >
                      <IoRemoveOutline
                        size={23}
                        style={{
                          backgroundColor: "green",
                          padding: "2px",
                          color: "white",
                          borderRadius: "50%",
                        }}
                      />
                    </IconButton>
                    <Popover
                      id={idRemove}
                      open={openRemove}
                      anchorEl={anchorElRemove}
                      onClose={handleCloseRemove}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Stack>
                        {forms && forms[index] && forms[index] && forms[index].metrixData && forms[index].metrixData.length > 2 &&
                          <Button
                            buttonName={"Delete Row"}
                            color="white"
                            style={{ width: "100%", border: "none" }}
                            onClick={removeRow}
                          />}
                        {forms && forms[index] && forms[index] && forms[index].metrixData && forms[index].metrixData[0].length > 2 &&

                          <Button
                            buttonName={"Delete Column"}
                            color="white"
                            style={{ width: "100%", border: "none" }}
                            onClick={removeColumn}
                          />
                        }
                      </Stack>
                    </Popover>
                  </div>
                }
              </Stack>
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ borderRightWidth: "2px" }}></TableCell>

                      {forms && forms[index] && forms[index] && forms[index].metrixData && forms[index].metrixData[0].map((_, colIndex) => (

                        colIndex != 0 && <TableCell style={{ borderRightWidth: "2px" }} key={colIndex}>
                          <input
                            type="text"
                            style={{
                              outline: "none",
                              border: "none",
                              backgroundColor: "transparent",
                              width: "100%",
                            }}
                            placeholder="Enter Column Name"
                            value={forms && forms[index] && forms[index] && forms[index].metrixData && forms[index].metrixData[0][colIndex].value}
                            onChange={(e) => handleInputChange(0, colIndex, e.target.value)}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {forms && forms[index] && forms[index] && forms[index].metrixData && forms[index].metrixData.map((row, rowIndex) => (

                      rowIndex != 0 && (
                        <TableRow
                          key={rowIndex}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            cursor: "pointer",
                          }}
                        >
                          {row.map((cell, cellIndex) => (
                            <TableCell key={cellIndex} style={{ borderRightWidth: "2px", width: "100%" }}>
                              {cellIndex === 0 ? (
                                <input
                                  type="text"
                                  style={{
                                    outline: "none",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    borderRightWidth: "2px",
                                    width: "100%"
                                  }}
                                  placeholder="Enter Row Text"
                                  value={cell.value}
                                  onChange={(e) => handleInputChange(rowIndex, cellIndex, e.target.value)}
                                />) : rowIndex == 1
                                ? <FormControl sx={{ width: "250px", display: "flex" }}>
                                  <Select
                                    id="demo-simple-select"
                                    value={forms[index].metrixData[0][cellIndex].type}
                                    displayEmpty
                                    onChange={(e) => {
                                      let newArray = [...forms];
                                      newArray[index].metrixData[0][cellIndex].type = e.target.value;
                                      setForms(newArray);
                                    }}
                                  >
                                    <MenuItem value="single">Radio Button</MenuItem>
                                    <MenuItem value="multiple">Check Box</MenuItem>
                                    <MenuItem value="text">Text Box</MenuItem>
                                    <MenuItem value="ratting">Ratting</MenuItem>
                                    <MenuItem value="selectbox">Select Box</MenuItem>
                                  </Select>
                                </FormControl> : columnType(forms[index].metrixData[0][cellIndex].type)}
                            </TableCell>
                          ))}
                        </TableRow>
                      )
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
            {/* <Stack
              sx={{
                display: "flex",
                justifyContent: "start",
                gap: "20px",
                flexDirection: "row",
                marginTop: "10px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  justifyContent: "Start",
                }}
              >
                <Typography
                  fontSize={{ xs: "10px", sm: "16px" }}
                  fontWeight={500}
                  mb={"2px"}
                >
                  Metrix:
                </Typography>
                <FormControl sx={{ width: "250px", display: "flex" }}>
                  <Select
                    id="demo-simple-select"
                    value={forms[index].fixRowSelectBoxType}
                    displayEmpty
                    onChange={handleTypeChange}
                  >
                    <MenuItem value="single">Radio Button</MenuItem>
                    <MenuItem value="multiple">Check Box</MenuItem>
                    <MenuItem value="text">Text Box</MenuItem>
                    <MenuItem value="ratting">Ratting</MenuItem>
                    <MenuItem value="selectbox">Select Box</MenuItem>
                  </Select>
                </FormControl>
              </Box>

            </Stack> */}
            {forms?.[index]?.metrixData?.[0]?.length > 0 &&
              forms[index].metrixData[0].filter(
                (data, INDEX) => data?.type == "selectbox"
              )?.length > 0 && (
                <Stack
                  sx={{
                    backgroundColor: "white.main",
                    borderRadius: "7px",
                    border: "1px solid #E0E0E0",
                    shadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    padding: "20px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    height: "fit-content",
                  }}
                >
                  <Stack
                    sx={{ display: "flex", flexDirection: "row", gap: "50px" }}
                  >
                    <Typography
                      fontSize={{ xs: "10px", sm: "16px" }}
                      fontWeight={500}
                      mb={"2px"}
                    >
                      Enter Bulk Option Data :-{" "}
                    </Typography>
                    {forms[formIndex].file == "" && (
                      <Button
                        buttonName={"Choose File"}
                        onClick={() => fileInputRef.current.click()}
                      />)}

                    <input
                      type="file"
                      onChange={(e) => {
                        handleFileChange(e);
                      }}
                      accept=".csv"
                      ref={fileInputRef}
                      style={{ display: "none" }}

                    />
                    {forms[formIndex].file && (
                      <Typography
                        fontSize={{ xs: "10px", sm: "16px" }}
                        fontWeight={500}
                        display={"flex"}
                        flexDirection={"column"}
                        width={forms[formIndex].file.length > 20 ? `${forms[formIndex].file.length * 10}px` : "100%"}
                      >
                        {forms[formIndex].file}
                      </Typography>
                    )}
                    {forms[index].file && (
                      <Button
                        buttonName={"Remove File"}
                        onClick={() => {
                          handleFileRemove(1);
                        }}
                        style={{ backgroundColor: "red", color: "white" }}
                      />
                    )}
                  </Stack>
                  <Divider />
                  <Divider />
                </Stack>
              )}
            <div className="metrixgrid">
              {forms?.[index]?.metrixData?.[0]?.length > 0 &&
                forms[index].metrixData[0].map((data, INDEX) =>
                  data?.type === "selectbox" ? (
                    <Stack
                      key={INDEX}
                      sx={{
                        backgroundColor: "white.main",
                        borderRadius: "7px",
                        border: "1px solid #E0E0E0",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        padding: "20px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        height: "fit-content",
                      }}
                    >
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "50px",
                        }}
                      >
                        <Typography
                          fontSize={{ xs: "10px", sm: "16px" }}
                          fontWeight={500}
                          mb={"2px"}
                        >
                          Scale Point {INDEX}
                        </Typography>


                      </Stack>
                      <Divider />

                      <Box sx={{ width: "100%" }}>
                        {data?.dataArray?.length > 0 &&
                          data.dataArray.map((ratings, indexs) => (
                            <Box
                              key={ratings.ids}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                                gap: "10px",
                              }}
                            >
                              <Box sx={{ flex: 1 }}>
                                <Input
                                  placeholder={`Enter Option ${indexs + 1}`}
                                  value={ratings.value}
                                  onChange={(e) =>
                                    handleRating(
                                      ratings.ids,
                                      e.target.value,
                                      INDEX,
                                      indexs
                                    )
                                  }
                                  sx={{ width: "100%" }}
                                />
                              </Box>
                              <Button
                                onClick={() =>
                                  handleDeleteRating(ratings.ids, INDEX, indexs)
                                }
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                                buttonName={
                                  <HiOutlineTrash
                                    style={{ fontSize: "15px" }}
                                  />
                                }
                              />
                            </Box>
                          ))}
                        <Box sx={{ marginTop: "10px" }}>
                          <Button
                            onClick={() => handleAddRating(INDEX)}
                            buttonName={"Add Dropdown Option"}
                          />
                        </Box>
                      </Box>
                      <Divider />
                    </Stack>
                  ) : null
                )}
            </div>
          </Stack>
        </Stack>
      </Stack >
    </>
  );
};

export default MetrixRowFixType;

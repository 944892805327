import { Stack, Typography, Box } from "@mui/material";
import React from "react";

const MediaCaptureView = ({ item, index }) => {
  console.log("MediaCaptureView", item);
  return (
    <>
      <Stack>
        <Typography>
          {" "}
          <span
            style={{
              color: "black",
              fontSize: "18px",
            }}
          >
            {index}.{" "}
          </span>
          {item?.question}
        </Typography>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {item?.answers?.length > 0 &&
            item?.answers?.map((ans) => {
              return (
                <div
                  style={{
                    width: "150px",
                    height: "150px",
                    marginLeft: "20px",
                    objectPosition: "cover",
                    backgroundColor: "black",
                    border: "none",
                    borderRadius: "5px",
                  }}
                >
                  <img
                    src={ans}
                    alt="media"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectPosition: "cover",
                      borderRadius: "5px",
                    }}
                  />
                </div>
              );
            })}
        </div>
      </Stack>
    </>
  );
};

export default MediaCaptureView;

import React, { useEffect, useState } from "react";
import { Button, Heading, MenuButton, Modal } from "../../components/Common";
import { Search, Pagination } from "../../components/Common/Table";
import { VscFilter } from "react-icons/vsc";
import {
  Box,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MdMoreVert } from "react-icons/md";
import { HiOutlineTrash } from "react-icons/hi";
import { AiOutlineEdit } from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import FilterRole from "./FilterRole";
import { RoleList, RoleCreate, RoleUpdate, RoleDelete, RoleStatusChange } from '../../Service/Role.service';
import { addAdminValidationSchema } from '../../helper/validation';
import { roleInitialValues, filterRoleInitialValues } from '../../helper/initialValues';
import { useDispatch } from 'react-redux';
import { notificationSuccess } from '../../state/Action/NotificationAction';
import { useFormik } from 'formik';
import moment from "moment";
import TableRowsLoader from "../../components/Common/Loader/Skeleton";

const Role = () => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });

  const [roleData, setRoleData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [search, setSearch] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: roleInitialValues,
    onSubmit: async (value) => {

      const payload = {
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email,
        role: value.role
      }

      let res = isModalOpen?.isEdit ? await RoleUpdate(isModalOpen?.id, payload) : await RoleCreate(payload);
      if (res?.status) {
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'Success'
        }))
        getRoleList();
        setIsModalOpen({ open: false, currentComponent: "", para: '', head: '', isEdit: false, isView: false });
        formik.resetForm();
      } else {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong. Please try again later.'
        }
        ))
      }

    },
    validationSchema: addAdminValidationSchema,
  });

  const formik1 = useFormik({
    initialValues: filterRoleInitialValues,
    onSubmit: async (value) => {
      setOpenDrawer(false);
      getRoleList();
    },
  });

  const getRoleList = async () => {
    setRoleData([]);
    let selectQry = `?page=${page + 1}&limit=${rowsPerPage}&search=${search}&status=${formik1.values.status}&role=${formik1.values.role}&sortBy=${sortConfig.key}&sortFlag=${sortConfig.direction}`;
    let res = await RoleList(selectQry);
    if (res?.status) {
      setRoleData(res.data);
      setTotalData(res.total);
    } else {
      setRoleData(null);
    }
  }

  useEffect(() => {
    getRoleList();
  }, [search, page, rowsPerPage, sortConfig]);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const changeStatus = async () => {
    let res = isModalOpen && isModalOpen?.currentComponent == "status" ? await RoleStatusChange(isModalOpen?.id) : isModalOpen && isModalOpen?.currentComponent == "delete" ? await RoleDelete(isModalOpen?.id) : null;
    if (res?.status) {
      dispatch(notificationSuccess({
        show: true,
        type: 'success',
        message: res.message || 'Success'
      }))
      getRoleList();
      setIsModalOpen({ open: false, currentComponent: "", para: '', head: '', id: '' });
    } else {
      dispatch(notificationSuccess({
        show: true,
        type: 'error',
        message: res.message || 'Something went wrong. Please try again later.'
      }
      ))
    }
  }

  return (
    <>
      <Heading head={"Role "} />
      <Stack gap={2} mt={1}>
        <Stack
          mb={1}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Search search={search} setSearch={setSearch} setPage={setPage} />
          <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
            <Button
              buttonName={"Add"}
              size="small"
              onClick={() => {
                navigate("/addrole");
              }}
            />
            <Button
              buttonName={<VscFilter />}
              style={{ padding: "10px 11px" }}
              color="white"
              size="small"
              onClick={toggleDrawer}
              variant="contained"
              sx={{ mt: 2 }}
            />
            <FilterRole
              openDrawer={openDrawer} toggleDrawer={toggleDrawer}
              formik={formik1}
            />
          </Stack>
        </Stack>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ cursor: "pointer" }} onClick={() => setSortConfig({ key: "roleName", direction: sortConfig && sortConfig.key === "roleName" && sortConfig.direction === "asc" ? "desc" : "asc" })} >
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div >
                      Name {sortConfig && sortConfig.key === "roleName" && sortConfig.direction === "desc" ? <IoIosArrowUp
                        sx={{
                          color: "rgba(107, 114, 128, .5)",
                          fontSize: "17px",
                          marginLeft: "3px",
                        }}
                      /> : <IoIosArrowDown sx={{
                        color: "rgba(107, 114, 128, .5)",
                        fontSize: "17px",
                        marginLeft: "3px",
                      }} />} </div>

                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }} onClick={() => setSortConfig({ key: "createdAt", direction: sortConfig && sortConfig.key === "createdAt" && sortConfig.direction === "asc" ? "desc" : "asc" })}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Created Date</div>
                    {sortConfig && sortConfig.key === "createdAt" && sortConfig.direction === "desc" ? <IoIosArrowUp
                      sx={{
                        color: "rgba(107, 114, 128, .5)",
                        fontSize: "17px",
                        marginLeft: "3px",
                      }}
                    /> : <IoIosArrowDown sx={{
                      color: "rgba(107, 114, 128, .5)",
                      fontSize: "17px",
                      marginLeft: "3px",
                    }} />}
                  </Stack>
                </TableCell>


                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Status</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"end"}
                  >
                    <div>Action</div>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roleData && roleData.map((data) => (
                <TableRow
                  key={data._id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                //   onClick={() => navigate("/auditor-details")}
                >

                  <TableCell style={{ textAlign: "left" }}>
                    {data?.roleName}
                  </TableCell>
                  <TableCell style={{ textAlign: "left" }}>
                    {moment(data?.createdAt).format('DD/MM/YYYY')}
                  </TableCell>


                  <TableCell style={{ textAlign: "left" }}>
                    <Switch size="small"
                      checked={data?.status === 1 ? true : false}
                      disabled={formik1.values.status == 2}

                      onClick={(e) => {
                        e.stopPropagation();
                        setIsModalOpen({ id: data?._id, open: true, currentComponent: "status", head: "Change Status", para: "Are you sure you want to Change the Status?" })
                      }}
                    />
                  </TableCell>
                  <TableCell
                    onClick={(e) => e.stopPropagation()}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger className="chatDropDown">
                        <MenuButton
                          icon={
                            <MdMoreVert
                              style={{ color: "#000", fontSize: "18px" }}
                            />
                          }
                          width="30px"
                          height="33px"
                        />
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Content
                        className="chatDropDownContent"
                        align="end"
                      >
                        <DropdownMenu.Item className="chatDropDownMenu"
                          onClick={() => {
                            navigate('/addrole', { state: { id: data?._id, isEdit: true } })
                          }
                          }>
                          <Stack
                            flexDirection={"row"}
                            gap={1}
                            alignItems={"center"}
                          >
                            <AiOutlineEdit style={{ fontSize: "15px" }} />
                            <Box sx={{ fontSize: "13px" }}>{"Edit"}</Box>
                          </Stack>
                        </DropdownMenu.Item>
                        <DropdownMenu.Item className="chatDropDownMenu">
                          <Stack
                            flexDirection={"row"}
                            gap={1}
                            alignItems={"center"}
                            onClick={() =>
                              setIsModalOpen({ open: true, currentComponent: "delete", head: "Delete Admin", para: "Are you sure you want to Delete this Admin?", id: data?._id })
                            }
                          >
                            <HiOutlineTrash style={{ fontSize: "15px" }} />
                            <Box sx={{ fontSize: "13px" }}>{"Delete"}</Box>
                          </Stack>
                        </DropdownMenu.Item>
                      </DropdownMenu.Content>
                    </DropdownMenu.Root>
                  </TableCell>
                </TableRow>
              ))}
              {roleData == null ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="6"
                  >
                    No records to display
                  </TableCell>
                </TableRow>

              ) : (
                roleData && roleData.length == 0 ? (<TableRowsLoader colNumber={4} rowsNum={10} />) : ("")
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {roleData != null && (
            <Pagination count={10} shape="rounded"
              totalData={totalData}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          )}
        </div>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
        handelClick={changeStatus}

      />
    </>
  );
};

export default Role;

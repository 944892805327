import { deleteRequest, get, post, put } from "../web.request";

export const AuditorList = async (query) => {
  return await get(`/auditorList${query}`)
}

export const AuditorCreate = async (data) => {
  return await post(`/auditorCreate `, data);
}

export const AuditorUpdate = async (id, data) => {
  return await put(`/auditorUpdate/${id}`, data);
}

export const AuditorDetail = async (id) => {
  return await get(`/auditorDetail/${id}`);
}

export const AuditorDelete = async (id) => {
  return await deleteRequest(`/auditordelete/${id}`);
}

export const DeviceDelete = async (id) => {
  return await deleteRequest(`/devicedelete/${id}`);
}

export const AuditorStatusChange = async (id, data) => {
  return await put(`/auditorStatusUpdate/${id}`, data);
}

export const AdminChnagePasswordOnMail = async (id) => {
  return await put(`/superAdminChangePassword/${id}`);
}

export const AuditorDropdown = async () => {
  return await get(`/auditorDropdown`);
}

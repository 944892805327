import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Typography,
  ListItemText,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Button, Input } from "../../Common";
import Select from "@mui/material/Select";
import Chip from '@mui/material/Chip';

const AddGroup = ({ handleClose, formik, data, modalOpen }) => {
  const [selectedAuditors, setSelectedAuditors] = useState([]);
  useEffect(() => {
    if (modalOpen.isEdit) {
      setSelectedAuditors(formik.values.auditors || []);
    } else {
      setSelectedAuditors(selectedAuditors);
    }
  }, [modalOpen.isEdit, formik.values.auditors]);

  const handleSelectChange = (event) => {
    const { target: { value } } = event;
    setSelectedAuditors(typeof value === 'string' ? value.split(',') : value);
    formik.setFieldValue('auditors', value);
  };

  const handleDelete = (id) => {
    const newItems = selectedAuditors.filter(auditorId => auditorId !== id);
    setSelectedAuditors(newItems);
    formik.setFieldValue('auditors', newItems);
  };

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.isEdit ? 'Edit' : 'Create'} Group
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container gap={2} maxWidth={{ sm: "400px" }} paddingY={1}>
          <Grid item xs={12}>
            <Input
              name="groupName"
              labelinput={"Group Name"}
              formik={formik}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Select Auditors</Typography>
            <FormControl fullWidth>
              {/* <InputLabel id="auditors-select-label">Select Auditors</InputLabel> */}
              <Select
                labelId="auditors-select-label"
                id="auditors-select"
                multiple
                value={selectedAuditors}
                onChange={handleSelectChange}
                renderValue={(selected) => (
                  <div>
                    {selected.map((id) => {
                      const auditor = data.find(auditor => auditor._id === id);
                      return auditor ? (
                        <Chip key={id} label={auditor.firstName} />
                      ) : null;
                    })}
                  </div>
                )}
              >
                {data && data.map((auditor) => (
                  <MenuItem key={auditor._id} value={auditor._id}  >
                    <ListItemText primary={auditor.firstName} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "10px" }}>
            {selectedAuditors.map((auditorId, index) => {
              const auditor = data.find(auditor => auditor._id === auditorId);
              return auditor ? (
                <Chip
                  key={index}
                  label={auditor.firstName}
                  variant="outlined"
                  onDelete={() => handleDelete(auditor._id)}
                />
              ) : null;
            })}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName={"Cancel"}
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button
          buttonName={modalOpen.isEdit ? 'Save' : 'Create'}
          size='small'
          onClick={formik.handleSubmit}
        />
      </DialogActions>
    </>
  );
};

export default AddGroup;

import React, { useState } from "react";
import { Input, Modal } from "../../../components/Common";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import SkipLogic from "../SkipLogic";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const TextForm = ({
  onDelete,
  onAddTextForm,
  index,
  forms,
  formIndex,
  setForms,
  getPayload,
  logicQuestionSelecteData,
  setLogicQuestionSelecteData,
  setDisplayLogicQuestionSelecteData,
  displaylogicQuestionSelecteData,
  skipLogicData,
  skipLogicCondition,
  setSkipLogicCondition,
  setDisplayLogicCondition,
  displayLogicCondition,
}) => {
  console.log("formssasasasas", forms[index]);

  const [isModalOpen, setIsModalOpen] = useState({
    id: "",
    open: false,
    currentComponent: "",
    head: "",
    para: "",
    type: "",
  });

  const handleRadioChange = (type) => {
    // const newArray = forms.map((form, i) => ({
    //   ...form,
    //   emailRequired: i == formIndex && type === "email",
    //   onlyNumber: i == formIndex && type === "number",
    //   dateRange: i == formIndex && type === "date",
    //   time: i == formIndex && type === "time",
    //   setDefaultDate:
    //     i == formIndex && type === "date" ? form.setDefaultDate : false,
    //   maxCharactor: 1000,
    //   minCharactor: 0,
    //   defaultDate: null,
    //   logicQuestionType: type === "email" ? "textEmail" : type,
    // }));

    let newArray = [...forms];
    newArray[index] = {
      ...forms[index],
      emailRequired: type === "email",
      onlyNumber: type === "number",
      text: type === "text",
      dateRange: type === "date",
      time: type === "time",
      setDefaultDate: type === "date" ? forms[index].setDefaultDate : false,
      maxCharactor: 1000,
      minCharactor: 0,
      defaultDate: null,
      logicQuestionType: type === "email" ? "textEmail" : type,
    };
    setForms(newArray);

    let newArray1 = [...logicQuestionSelecteData];
    newArray1[formIndex] = {
      ...logicQuestionSelecteData[index],
      type: type === "email" ? "textEmail" : type,
    };
    setLogicQuestionSelecteData(newArray1);

    let newArray2 = [...displaylogicQuestionSelecteData];
    newArray2[index] = {
      ...displaylogicQuestionSelecteData[index],
      type: type === "email" ? "textEmail" : type,
    };
    setDisplayLogicQuestionSelecteData(newArray2);
  };

  const handleChange = (event) => {
    let newArray = [...forms];
    let ids = uuidv4();

    newArray[index] = {
      ...getPayload(event.target.value),
      type: event.target.value,
      logicQuestionType:
        event.target.value == "text" ? "textEmail" : event.target.value,
      index: index,
      ids: ids,
    };

    let newArray1 = [...logicQuestionSelecteData];
    newArray1[index] = {
      ...getPayload(event.target.value),
      type: event.target.value,
      index: index,
      ids: ids,
      name: forms[index].question,
      value: "",
    };
    setLogicQuestionSelecteData(newArray1);

    let newArray2 = [...displaylogicQuestionSelecteData];
    newArray2[index] = {
      type: event.target.value,
      index: index,
      ids: ids,
      name: forms[index].question,
      value: "",
    };
    setDisplayLogicQuestionSelecteData(newArray2);
    setForms(newArray);
  };
  //////////
  const [value, setValue] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Stack
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          flexDirection: "row",
          width: "100%",
          marginBottom: "20px",
        }}
      >
        <Stack sx={{ width: "100%" }}>
          <Stack
            sx={{
              backgroundColor: "white.main",
              borderRadius: "7px",
              padding: "20px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              height: "fit-content",
            }}
          >
            <Stack sx={{ display: "flex", flexDirection: "row", gap: "20px" }}>
              <Typography
                fontSize={{ xs: "10px", sm: "16px" }}
                fontWeight={500}
                mb={"2px"}
                width={"10px"}
                display={"flex"}
                flexDirection={"column"}
              >
                {index + 1}.
              </Typography>
              <Stack
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <textarea
                  placeholder="Please Enter your Question text here..."
                  className="defaultinput textareaformcreation"
                  onChange={(e) => {
                    let newArray = [...forms];
                    newArray[index].question = e.target.value;
                    setForms(newArray);
                    let newArray1 = [...logicQuestionSelecteData];
                    newArray1[index].name = e.target.value;
                    setLogicQuestionSelecteData(newArray1);

                    let newArray2 = [...displaylogicQuestionSelecteData];
                    newArray2[index].name = e.target.value;
                    setDisplayLogicQuestionSelecteData(newArray2);
                  }}
                  value={forms[index].question}
                />
                <TextField placeholder="Text Input" disabled />
                <Stack width={"100%"}>
                  <Input
                    placeholder="Instruction Text"
                    onChange={(e) => {
                      let newArray = [...forms];
                      newArray[index].intructinText = e.target.value;
                      setForms(newArray);
                    }}
                    value={forms[index].intructinText}
                    style={{ width: "100%" }}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
              className="formcard"
            >
              <Stack className="formcreationmain">
                <Box className="headerrow">
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <FormControl>
                      <Select
                        value={forms[index].type}
                        onChange={handleChange}
                        displayEmpty
                        sx={{
                          width: 300,
                          ".MuiSelect-select": {
                            padding: "10px",
                          },
                        }}
                      >
                        <MenuItem
                          value=""
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          Select Question Type
                        </MenuItem>
                        <MenuItem
                          value="text"
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          Text
                        </MenuItem>
                        <MenuItem
                          value="multiSelect"
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          {" "}
                          Multiple Choice
                        </MenuItem>
                        <MenuItem
                          value="scale"
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          {" "}
                          Scale
                        </MenuItem>
                        <MenuItem
                          value="DropDown"
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          {" "}
                          Drop Down
                        </MenuItem>
                        <MenuItem
                          value="Metrix"
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          {" "}
                          Metrix
                        </MenuItem>
                        <MenuItem
                          value="Scan"
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          Media Capture
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <Box sx={{ width: "fit-content" }}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={value}
                          onChange={handleChange1}
                          aria-label="basic tabs example"
                        >
                          <Tab label="Options" {...a11yProps(0)} />
                          <Tab label="Logic" {...a11yProps(1)} />
                        </Tabs>
                      </Box>
                    </Box>
                  </Stack>

                  <label style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      style={{
                        marginRight: "10px",
                        height: "17px",
                        width: "17px",
                      }}
                      onChange={(e) => {
                        let newArray = [...forms];
                        newArray[index].answerRequired = e.target.checked;
                        setForms(newArray);
                      }}
                      checked={forms[index].answerRequired}
                    />
                    Answer Required
                  </label>
                </Box>
              </Stack>
              <div className="verticalline"></div>
              <CustomTabPanel value={value} index={0}>
                <Box
                  style={{
                    display: "flex",
                    gap: "30px",
                    alignItems: "start",
                    flexDirection: "column",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "30px",
                      alignItems: "start",
                    }}
                  >
                    <Stack sx={{ display: "flex", justifyContent: "center" }}>
                      <label style={{ display: "flex", alignItems: "center" }}>
                        <input
                          type="radio"
                          checked={forms[index].text}
                          style={{
                            marginRight: "10px",
                            height: "17px",
                            width: "17px",
                          }}
                          onChange={() => handleRadioChange("text")}
                        />
                        Text
                      </label>

                      <label style={{ display: "flex", alignItems: "center" }}>
                        <input
                          type="radio"
                          checked={forms[index].emailRequired}
                          style={{
                            marginRight: "10px",
                            height: "17px",
                            width: "17px",
                          }}
                          onChange={() => handleRadioChange("email")}
                        />
                        Email Required
                      </label>
                      <label style={{ display: "flex", alignItems: "center" }}>
                        <input
                          type="radio"
                          checked={forms[index].onlyNumber}
                          style={{
                            marginRight: "10px",
                            height: "17px",
                            width: "17px",
                          }}
                          onChange={() => handleRadioChange("number")}
                        />
                        Only Number
                      </label>
                      <label style={{ display: "flex", alignItems: "center" }}>
                        <input
                          type="radio"
                          checked={forms[index].time}
                          style={{
                            marginRight: "10px",
                            height: "17px",
                            width: "17px",
                          }}
                          onChange={() => handleRadioChange("time")}
                        />
                        Only Time
                      </label>
                      <label style={{ display: "flex", alignItems: "center" }}>
                        <input
                          type="radio"
                          checked={forms[index].dateRange}
                          style={{
                            marginRight: "10px",
                            height: "17px",
                            width: "17px",
                          }}
                          onChange={() => handleRadioChange("date")}
                        />
                        Date Range
                      </label>

                      {forms[index].dateRange && (
                        <label
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <input
                            type="checkbox"
                            style={{
                              marginRight: "10px",
                              height: "17px",
                              width: "17px",
                            }}
                            onChange={(e) => {
                              let newArray = [...forms];
                              newArray[index].setDefaultDate = e.target.checked;

                              setForms(newArray);
                            }}
                            checked={forms[index].setDefaultDate}
                          />
                          Set Default Date
                        </label>
                      )}
                    </Stack>
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Box>
                        <Typography
                          fontSize={{ xs: "10px", sm: "13px" }}
                          fontWeight={500}
                          mb={"2px"}
                        >
                          {forms[index].onlyNumber && "Min Number:"}{" "}
                          {forms[index].setDefaultDate &&
                            "Default Date & Time:"}
                          {!forms[index].onlyNumber &&
                            !forms[index].dateRange &&
                            !forms[index].dateRange &&
                            !forms[index].time &&
                            "Min Characters:"}
                        </Typography>
                        {!forms[index].dateRange && !forms[index].time ? (
                          <input
                            type="number"
                            className="defaultinput"
                            style={{ height: "38px", paddingLeft: "6px" }}
                            onChange={(e) => {
                              let newArray = [...forms];
                              newArray[index].minCharactor = parseInt(
                                e.target.value
                              );
                              setForms(newArray);
                            }}
                            value={forms[index].minCharactor}
                          />
                        ) : forms[index].dateRange &&
                          !forms[index].time &&
                          forms[index].setDefaultDate ? (
                          <div style={{ width: "237px" }}>
                            <input
                              type="datetime-local"
                              className="defaultinput"
                              style={{ height: "38px", paddingLeft: "6px" }}
                              onChange={(e) => {
                                let newArray = [...forms];
                                newArray[index].defaultDate = e.target.value;

                                setForms(newArray);
                              }}
                              value={forms[index].defaultDate}
                            />
                          </div>
                        ) : !forms[index].time ? (
                          <>
                            <div style={{ width: "237px" }}>
                              <Typography
                                fontSize={{ xs: "10px", sm: "13px" }}
                                fontWeight={500}
                                mb={"2px"}
                              >
                                Start Date
                              </Typography>
                              <input
                                type="datetime-local"
                                label="Start Date"
                                className="defaultinput"
                                style={{ height: "38px", paddingLeft: "6px" }}
                              />
                            </div>
                            <Divider />
                            <div style={{ width: "237px" }}>
                              <Typography
                                fontSize={{ xs: "10px", sm: "13px" }}
                                fontWeight={500}
                                mb={"2px"}
                              >
                                End Date
                              </Typography>
                              <input
                                type="datetime-local"
                                label="Start Date"
                                className="defaultinput"
                                style={{ height: "38px", paddingLeft: "6px" }}
                              />
                            </div>
                          </>
                        ) : (
                          <div style={{ width: "237px" }}>
                            <Typography
                              fontSize={{ xs: "10px", sm: "13px" }}
                              fontWeight={500}
                              mb={"2px"}
                            >
                              Time
                            </Typography>
                            <input
                              type="time"
                              label="Start Time"
                              className="defaultinput"
                              style={{ height: "38px", paddingLeft: "6px" }}
                            />
                          </div>
                        )}
                      </Box>
                      <Box>
                        <Typography
                          fontSize={{ xs: "10px", sm: "13px" }}
                          fontWeight={500}
                          mb={"2px"}
                        >
                          {forms[index].onlyNumber && "Max Number:"}{" "}
                          {!forms[index].onlyNumber &&
                            !forms[index].dateRange &&
                            !forms[index].time &&
                            "Max Characters:"}
                        </Typography>

                        {!forms[index].setDefaultDate &&
                          !forms[index].time &&
                          !forms[index].dateRange && (
                            <input
                              type="number"
                              className="defaultinput"
                              style={{ height: "38px", paddingLeft: "6px" }}
                              onChange={(e) => {
                                let newArray = [...forms];
                                newArray[index].maxCharactor = e.target.value;
                                setForms(newArray);
                              }}
                              value={forms[index].maxCharactor}
                            />
                          )}
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Stack spacing={2}>
                  <SkipLogic
                    logicQuestionSelecteData={logicQuestionSelecteData}
                    forms={forms}
                    setForms={setForms}
                    index={index}
                    setLogicQuestionSelecteData={setLogicQuestionSelecteData}
                    skipLogicData={skipLogicData}
                    skipLogicCondition={skipLogicCondition}
                    setSkipLogicCondition={setSkipLogicCondition}
                    displayLogicCondition={displayLogicCondition}
                    setDisplayLogicCondition={setDisplayLogicCondition}
                    displaylogicQuestionSelecteData={
                      displaylogicQuestionSelecteData
                    }
                    setDisplayLogicQuestionSelecteData={
                      setDisplayLogicQuestionSelecteData
                    }
                  />
                </Stack>
              </CustomTabPanel>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handelClick={() => {
          if (isModalOpen.type === "delete") {
            onDelete();
            setIsModalOpen({
              open: false,
              currentComponent: "",
              para: "",
              head: "",
              type: "",
            });
          }
          if (isModalOpen.type === "copy") {
            console.log("copy");
            onAddTextForm("text");
            setIsModalOpen({
              open: false,
              currentComponent: "",
              para: "",
              head: "",
              type: "",
            });
          }
        }}
        handleClose={() =>
          setIsModalOpen({
            open: false,
            currentComponent: "",
            para: "",
            head: "",
            type: "",
          })
        }
      />
    </>
  );
};

export default TextForm;

import React, { useEffect, useState } from "react";
import { BackButton, Heading, Input, Button } from "../../components/Common";
import {
  Checkbox,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";
import { PageList, RoleDetail, RoleCreate, RoleUpdate } from '../../Service/Role.service';
import { useFormik } from 'formik';
import { roleAddInitialValues } from '../../helper/initialValues';
import { roleAddValidationSchema } from '../../helper/validation';
import { useLocation } from 'react-router-dom';
import { notificationSuccess } from '../../state/Action/NotificationAction';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Addrole = () => {

  const [pageListData, setPageListData] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleCheckboxChange = (moduleIndex, permission) => {
    setPermissions(prevPermissions => {
      const newPermissions = [...prevPermissions];
      newPermissions[moduleIndex] = {
        ...newPermissions[moduleIndex],
        [permission]: !newPermissions[moduleIndex][permission],
      };
      return newPermissions;
    });
  };

  const getPageList = async () => {
    const res = await PageList();
    if (res?.status) {
      setPageListData(res.data);
      setPermissions(res.data.map((item) => ({
        readP: false,
        writeP: false,
        deleteP: false,
        pageId: item._id,
        pageName: item.pageName,
      })));
    } else {
      setPageListData([]);
    }
  }

  // useEffect(() => {
  //   getPageList();
  // }, []);


  const formik = useFormik({
    initialValues: roleAddInitialValues,
    onSubmit: async (value) => {

      const payload = {
        roleName: value.roleName,
        permissions: permissions,
      }


      let res = isEdit ? await RoleUpdate(editId, payload) : await RoleCreate(payload);
      if (res?.status) {
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'Success'
        }))
        formik.resetForm();
        navigate('/role');

      } else {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong. Please try again later.'
        }
        ))
      }

    },
    validationSchema: roleAddValidationSchema,
  });

  useEffect(() => {
    if (state && state?.isEdit && state?.id) {
      setIsEdit(true);
      setEditId(state.id);
      getroleDetails(state.id);
    } else {
      setEditId(null);
      setIsEdit(false);
      getPageList();
    }

  }, [state]);

  const getroleDetails = async (id) => {
    let res = await RoleDetail(id);
    if (res?.status) {
      formik.setValues({
        roleName: res.data.roleName,
      });
      if (res && res?.data && res?.data?.permissions) {
        setPermissions(res.data.permissions.map((item) => ({
          readP: item.readP == 1 ? true : false,
          writeP: item.writeP == 1 ? true : false,
          deleteP: item.deleteP == 1 ? true : false,
          pageId: item.pageId,
          id: item._id,
          pageName: item.pageName,
        })));
      }
    }
  }


  return (
    <>
      <Stack gap={2} mt={1}>
        <Stack
          mb={1}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack
            flexDirection={"row"}
            justifyContent={"flex-start"}
            gap={1}
            alignItems={"center"}
          >
            <BackButton />
            <Heading head={"Add Role"} />
          </Stack>
        </Stack>
        <div>
          <Grid container gap={2}>
            <div style={{ display: "flex", width: "100%", gap: "10px" }}>
              <Grid item xs={12}>
                <Input labelinput={"Role Name"}
                  name='roleName'
                  formik={formik}
                  maxLength={50} />
              </Grid>
            </div>
            <Typography variant="h6">Role Management</Typography>
            <TableContainer component={Paper}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Stack
                        flexDirection={"row"}
                        gap={2}
                        alignItems={"center"}
                        justifyContent={"start"}
                      >
                        <div>{"Module Name"}</div>
                        <IoIosArrowDown />
                      </Stack>
                    </TableCell>
                    <TableCell align="center">
                      <Stack
                        flexDirection={"row"}
                        gap={2}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <div>{"Read"}</div>
                        {/* <IoIosArrowDown /> */}
                      </Stack>
                    </TableCell>
                    <TableCell align="center">
                      <Stack
                        flexDirection={"row"}
                        gap={2}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <div>{"Write"}</div>
                      </Stack>
                    </TableCell>
                    <TableCell align="center">
                      <Stack
                        flexDirection={"row"}
                        gap={2}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <div>{"Delete"}</div>
                      </Stack>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {permissions.map((data, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell style={{ textAlign: "left" }}>
                        {data.pageName}
                      </TableCell>
                      <TableCell align='center'>
                        <Checkbox
                          checked={permissions[index].readP}
                          onChange={() => handleCheckboxChange(index, 'readP')}
                        />
                      </TableCell>
                      <TableCell align='center'>
                        <Checkbox
                          checked={permissions[index].writeP}
                          onChange={() => handleCheckboxChange(index, 'writeP')}
                        />
                      </TableCell>
                      <TableCell style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Checkbox
                          checked={permissions[index].deleteP}
                          onChange={() => handleCheckboxChange(index, 'deleteP')}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </div>
        <Stack flexDirection={'row'} mt={2} gap={1}>
          <Button buttonName='Cancel' size='small' color='white'
            onClick={() => navigate('/role')}
          />
          <Button buttonName={`${isEdit ? 'Update' : 'Create'}`}
            size='small' onClick={formik.handleSubmit} />
        </Stack>
      </Stack>
    </>
  );
};

export default Addrole;

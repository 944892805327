import React, { useState } from "react";
import { Button, Heading } from "../../components/Common";
import { Stack } from "@mui/material";
import { VscFilter } from "react-icons/vsc";
import { Search } from "../../components/Common/Table";
import { useNavigate } from "react-router-dom";

const View = () => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);

  return (
    <>
      <Heading head={"View"} />
      <Stack gap={2} mt={1}>
        <Stack
          mb={1}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Search search={search} setSearch={setSearch} setPage={setPage} />
          <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
            {/* <Button
              buttonName={"Add"}
              size="small"
              // onClick={() => {
              //   setIsModalOpen({
              //     open: true,
              //     currentComponent: "addadmin",
              //   });
              // }}
            /> */}
            <Button
              buttonName={<VscFilter />}
              style={{ padding: "10px 11px" }}
              color="white"
              size="small"
              //   onClick={toggleDrawer}
              variant="contained"
              sx={{ mt: 2 }}
            />
            {/* <FilterForm
              value={value}
              setValue={setValue}
              openDrawer={openDrawer}
              toggleDrawer={toggleDrawer}
            /> */}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default View;

import { Box, Grid, Stack, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'

const CommonView = ({ submittedForm }) => {
    console.log("submittedForm", submittedForm);
    return (
        <>
            <Stack >
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Typography style={{ color: 'gray' }} fontSize={{ sx: "12px", xs: "14px" }}>Submitted Date</Typography>
                        <div style={{ color: "black", }}>{moment(submittedForm?.submittedDate).format('DD-MM-YYYY HH:mm')}</div>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography style={{ color: 'gray' }} fontSize={{ sx: "12px", xs: "14px" }}>Form Title</Typography>
                        <div style={{ color: "black", }}>{submittedForm?.fromId}</div>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography style={{ color: 'gray' }} fontSize={{ sx: "12px", xs: "14px" }}>Auditor Name</Typography>
                        <div style={{ color: "black", }}>{submittedForm?.auditorId?.firstName + " " + submittedForm?.auditorId?.lastName}</div>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography style={{ color: 'gray' }} fontSize={{ sx: "12px", xs: "14px" }}>Device Name</Typography>
                        <div style={{ color: "black", }}>{submittedForm?.deviceName}</div>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography style={{ color: 'gray' }} fontSize={{ sx: "12px", xs: "14px" }}>Device Id</Typography>
                        <div style={{ color: "black", }}>{submittedForm?.deviceId}</div>
                    </Grid>

                </Grid>
            </Stack>
        </>
    )
}

export default CommonView
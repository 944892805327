import { deleteRequest, get, post, put } from "../web.request";

export const GroupList = async (query) => {
  return await get(`/groupList${query}`)
}

export const GroupCreate = async (data) => {
  return await post(`/groupCreate `, data);
}

export const GroupUpdate = async (id, data) => {
  return await put(`/groupUpdate/${id}`, data);
}

export const GroupDetail = async (id) => {
  return await get(`/groupDetail/${id}`);
}

export const GroupDelete = async (id) => {
  return await deleteRequest(`/groupdelete/${id}`);
}

export const GroupStatusChange = async (id, data) => {
  return await put(`/groupStatusUpdate/${id}`, data);
}

export const GroupDropdown = async () => {
  return await get(`/groupDropdown`);
}
import React from 'react'
import { Drawer, Grid, Typography, Divider, Stack, IconButton, Slider, Box } from '@mui/material';
import { CloseIcon } from '../../helper/Icons'
import { Button, Input, Select } from '../../components/Common';
import { statusFilter } from '../../helper/Helper';
import CommonInput from '../../components/Common/Input';
import CommonSelect from '../../components/Common/Select';

const FilterForm = ({ openDrawer, formik, value, setValue, toggleDrawer }) => {
  const drawerWidth = 250;

  return (
    <>
      <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, padding: 2 },
        }}
      >
        <Box height={'100%'}>
          <Grid container>
            <Grid item xs={12}>
              <Typography fontWeight={600} fontSize={'18px'}>{("Filter")}</Typography>
              <IconButton
                aria-label="close"
                onClick={toggleDrawer}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon sx={{ width: '20px', height: '20px' }} />
              </IconButton>
            </Grid>
            <Grid item xs={12} marginY={'5px'}>
              <Divider sx={{ borderColor: "gray.main" }} />
            </Grid>

            <Grid item xs={12} marginY={2}>
              <Grid container gap={2}>
                <Grid item xs={12}>
                  <CommonInput labelinput={('From Expire Date')}
                    name='fromExpireDate'
                    type="date"
                    isDate={true}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input labelinput={('To Expire Date')}
                    name='toExpireDate'
                    type="date"
                    formik={formik}
                    isDate={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input labelinput={('From Published Date')}
                    name='fromPublishedDate'
                    type="date"
                    formik={formik}
                    isDate={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input labelinput={('To Published Date')}
                    name='toPublishedDate'
                    type="date"
                    formik={formik}
                    isDate={true}

                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <CommonSelect
                  labelinput={('Status')}
                  selectHead={('None')}
                  name='status'
                  onChange={(e) => {
                    console.log("yerywieywieyw", e.target.value);
                    formik.setFieldValue('status', e.target.value);
                  }}
                  selectList={[
                    { name: ('Active'), id: 1 },
                    { name: ('Inactive'), id: '0' },
                    { name: ('Deleted'), id: '2' }
                  ]}
                  formik={formik}
                  value={formik.values.status}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Stack flexDirection={'row'} gap={1}>
          <Button buttonName={('Clear')} color='white' style={{ width: '100%' }} size='small' onClick={() => {
            // resetForm();
            setValue([]);
            toggleDrawer();
            // handleSubmit();
          }} />
          <Button buttonName={('Filter')} style={{ width: '100%' }} size='small' onClick={() => {
            // handleSubmit();
            toggleDrawer();
            formik.handleSubmit();
          }} />
        </Stack>
      </Drawer>
    </>
  )
}

export default FilterForm
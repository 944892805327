import React from 'react'
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Input } from '../../../components/Common'
import CommonInput from '../../../components/Common/Input'
import Rating from '@mui/material/Rating';


const MetrixView = ({ item, index }) => {
    console.log("type", item)

    const parseBoolean = (value) => {
        if (value == "true") {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            <Box>
                <Box>
                    <Typography style={{ color: 'gray' }} fontSize={{ sx: "12px", xs: "14px" }}> <span style={{
                        color: 'black',
                        fontSize: "18px",

                    }}>{index}.{" "}</span> {" "}{item?.question}</Typography>
                </Box>
                <Box>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {item?.matrixAnswers[0]?.map((data, i) => (
                                        <>
                                            <TableCell>{data?.value}</TableCell>
                                        </>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {item?.matrixAnswers?.map((data, i) => {
                                    return (
                                        <>
                                            <TableRow
                                                key={i}>
                                                {i != 0 && data?.map((answer, j) => (
                                                    <>
                                                        <TableCell key={j}>
                                                            {answer?.type == "single" ? <input type="radio" checked={parseBoolean(answer.value)} /> : answer?.type == "multiple" ? <input checked={parseBoolean(answer.value)}
                                                                type="checkbox" /> : answer.type == "ratting" ? <Rating
                                                                    name="simple-controlled"
                                                                    value={parseInt(answer.value)}

                                                                /> : answer?.value}
                                                        </TableCell>
                                                    </>
                                                ))}
                                            </TableRow>
                                        </>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </>
    )
}

export default MetrixView
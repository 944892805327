import React, { useEffect, useState } from "react";
import { Button, Heading, MenuButton, Modal } from "../../components/Common";
import {
  Box,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { VscFilter } from "react-icons/vsc";
import { Search, Pagination } from "../../components/Common/Table";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdMoreVert, MdPassword } from "react-icons/md";
import { BsEye } from "react-icons/bs";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { AiOutlineEdit } from "react-icons/ai";
import { HiOutlineTrash } from "react-icons/hi2";
import { GroupCreate, GroupDelete, GroupList, GroupStatusChange, GroupUpdate } from "../../Service/group.service";
import { useNavigate } from "react-router-dom";
import { notificationSuccess } from '../../state/Action/NotificationAction';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import moment from 'moment';
import { filterGroupInitialValues, groupInitialValues } from "../../helper/initialValues";
import { addgroupValidationSchema } from "../../helper/validation";
import { AuditorDropdown } from "../../Service/Auditor.service";
import FilterGroup from "./FiltterGroup";
import TableRowsLoader from "../../components/Common/Loader/Skeleton";

const Group = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [groupdata, setGroupData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const [auditordata, setAuditorData] = useState([]);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });


  const formik = useFormik({
    initialValues: groupInitialValues,
    onSubmit: async (values) => {
      const payload = {
        groupName: values.groupName,
        auditors: values.auditors,
      };
      const res = isModalOpen.isEdit
        ? await GroupUpdate(isModalOpen.id, payload)
        : await GroupCreate(payload);

      if (res?.status) {
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'Success',
        }));
        getGroupList();
        setIsModalOpen({ open: false, currentComponent: "", para: '', head: '', isEdit: false, isView: false });
        formik.resetForm();
      } else {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong. Please try again later.'
        }));
      }
    },
    validationSchema: addgroupValidationSchema,
  });

  const formik1 = useFormik({
    initialValues: filterGroupInitialValues,
    onSubmit: () => {
      setOpenDrawer(false);
      getGroupList();
    },
  });

  console.log("dsdsdsdsdsdsd", formik1.values)

  const getDropdownData = async () => {
    let res = await AuditorDropdown();
    if (res?.status) {
      setAuditorData(res.data);
    } else {
      setAuditorData([]);
    }
  }
  useEffect(() => {
    getDropdownData();
  }, []);

  const getGroupList = async () => {
    setGroupData([]);
    const selectQry = `?search=${search}&page=${page + 1}&limit=${rowsPerPage}&status=${formik1.values.status}&sortBy=${sortConfig.key}&sortFlag=${sortConfig.direction}`;
    const res = await GroupList(selectQry);

    if (res?.status) {
      setGroupData(res.data);
      setTotalData(res.total);
    } else {
      setGroupData(null);
    }
  };

  useEffect(() => {
    getGroupList();
  }, [search, page, rowsPerPage, sortConfig])

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const changeStatus = async () => {
    const res = isModalOpen.currentComponent === "status"
      ? await GroupStatusChange(isModalOpen.id)
      : isModalOpen.currentComponent === "delete"
        ? await GroupDelete(isModalOpen.id)
        : null;

    if (res?.status) {
      dispatch(notificationSuccess({
        show: true,
        type: 'success',
        message: res.message || 'Success',
      }));
      getGroupList();
      setIsModalOpen({ open: false, currentComponent: "", para: '', head: '', id: '' });
    } else {
      dispatch(notificationSuccess({
        show: true,
        type: 'error',
        message: res.message || 'Something went wrong. Please try again later.'
      }));
    }
  };

  // const dataShorting = (name) => {
  //   setFlagName(name);
  //   setSortFlag((prevSortFlag) => (prevSortFlag === "desc" ? "asc" : "desc"));
  //   setFieldName(name);
  //   getGroupList();
  // };

  return (
    <>
      <Heading head={"Group"} />
      <Stack gap={2} mt={1}>
        <Stack
          mb={1}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Search search={search} setSearch={setSearch} setPage={setPage} />
          <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
            <Button
              buttonName={"Add"}
              size="small"
              onClick={() => {
                setIsModalOpen({
                  open: true,
                  currentComponent: "addgroup",
                });
              }}
            />
            <Button
              buttonName={<VscFilter />}
              style={{ padding: "10px 11px" }}
              color="white"
              size="small"
              variant="contained"
              onClick={toggleDrawer}
              sx={{ mt: 2 }}
            />
            <FilterGroup openDrawer={openDrawer} toggleDrawer={toggleDrawer}
              formik={formik1} />
          </Stack>
        </Stack>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ cursor: "pointer" }} onClick={() => setSortConfig({ key: "createdAt", direction: sortConfig && sortConfig.key === "createdAt" && sortConfig.direction === "asc" ? "desc" : "asc" })}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Date</div>
                    {sortConfig && sortConfig.key === "createdAt" && sortConfig.direction === "desc" ? <IoIosArrowUp
                      sx={{
                        color: "rgba(107, 114, 128, .5)",
                        fontSize: "17px",
                        marginLeft: "3px",
                      }}
                    /> : <IoIosArrowDown sx={{
                      color: "rgba(107, 114, 128, .5)",
                      fontSize: "17px",
                      marginLeft: "3px",
                    }} />}
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }} >
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Group Name</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Auditors Count</div>
                  </Stack>
                </TableCell>

                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Status</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"end"}
                  >
                    <div>Action</div>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupdata && groupdata.map((data) => (
                <TableRow

                >
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                  >
                    {moment(data?.createdAt).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell style={{ textAlign: "left" }}>
                    {data.groupName}
                  </TableCell>
                  <TableCell style={{ textAlign: "left" }}>
                    {data.auditorCount}
                  </TableCell>

                  <TableCell style={{ textAlign: "left" }}>
                    <Switch size="small"
                      checked={data?.status === 1}
                      disabled={formik1.values.status == 2}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsModalOpen({ id: data?._id, open: true, currentComponent: "status", head: "Change Status", para: "Are you sure you want to Change the Status?" });
                      }}
                    />
                  </TableCell>
                  <TableCell
                    onClick={(e) => e.stopPropagation()}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger className="chatDropDown">
                        <MenuButton
                          icon={
                            <MdMoreVert
                              style={{ color: "#000", fontSize: "18px" }}
                            />
                          }
                          width="30px"
                          height="33px"
                        />
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Content
                        className="chatDropDownContent"
                        align="end"
                      >
                        <DropdownMenu.Item className="chatDropDownMenu">
                          <Stack
                            flexDirection={"row"}
                            gap={1}
                            alignItems={"center"}
                            onClick={() => {
                              formik.setFieldValue('groupName', data?.groupName);
                              formik.setFieldValue('auditors', data?.auditors);
                              setIsModalOpen({
                                open: true,
                                currentComponent: "addgroup",
                                id: data?._id,
                                isEdit: true
                              });
                            }}
                          >
                            <AiOutlineEdit style={{ fontSize: "15px" }} />
                            <Box sx={{ fontSize: "13px" }}>{"Edit"}</Box>
                          </Stack>
                        </DropdownMenu.Item>
                        <DropdownMenu.Item className="chatDropDownMenu">
                          <Stack
                            flexDirection={"row"}
                            gap={1}
                            alignItems={"center"}
                            onClick={() =>
                              setIsModalOpen({ open: true, currentComponent: "delete", head: "Delete Group", para: "Are you sure you want to Delete this Group?", id: data?._id })
                            }
                          >
                            <HiOutlineTrash style={{ fontSize: "15px" }} />
                            <Box sx={{ fontSize: "13px" }}>{"Delete"}</Box>
                          </Stack>
                        </DropdownMenu.Item>
                      </DropdownMenu.Content>
                    </DropdownMenu.Root>
                  </TableCell>
                </TableRow>
              ))}
              {groupdata == null ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="6"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : (
                groupdata && groupdata.length == 0 ? (<TableRowsLoader colNumber={5} rowsNum={10} />) : ("")
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
        {groupdata != null && (
          <Pagination count={10} shape="rounded"
            totalData={totalData}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </div>
      <Modal
        modalOpen={isModalOpen}
        formik={formik}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "", id: "" })
          formik.resetForm()
        }
        }
        data={auditordata}
        handelClick={changeStatus}
      />
    </>
  );
};

export default Group;
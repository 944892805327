
export const statusFilter = [
    { name: 'Active', _id: '1' },
    { name: 'Inactive', _id: '0' },
    { name: 'Deleted', _id: '2' }
]

export const textLogicFilter = [
    { name: 'Contains', _id: 'contains' },
    { name: 'Starts With', _id: 'startsWith' },
    { name: 'Ends With', _id: 'endsWith' },
    { name: 'Equal', _id: 'equal' },
    { name: 'Not Equal', _id: 'notEqual' },
    { name: 'isAnswered', _id: 'isAnswered' },
    { name: 'isNotAnswered', _id: 'isNotAnswered' }
]

export const scaleLogicFilter = [
    { name: 'Equal', _id: 'equal' },
    { name: 'Not Equal', _id: 'notEqual' },
    { name: 'Greater Than', _id: 'greaterThan' },
    { name: 'Less Than', _id: 'lessThan' },
    { name: 'isAnswered', _id: 'isAnswered' },
    { name: 'isNotAnswered', _id: 'isNotAnswered' }
]


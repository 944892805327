import {
  Box,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Heading, MenuButton } from "../../components/Common";
import { VscFilter } from "react-icons/vsc";
import { Pagination, Search } from "../../components/Common/Table";
import { useNavigate } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { BsEye } from "react-icons/bs";
import FilterSubmitform from "./FilterSubmitform";
import { submittedFormList } from "../../Service/submittedForm";
import moment from "moment";
import TableRowsLoader from "../../components/Common/Loader/Skeleton";
import { AuditorDropdown } from "../../Service/Auditor.service";

import {
  formFilterInitialValues,
  submitformFilterInitialValues,
} from "../../helper/initialValues";
import { useFormik } from "formik";

const Submitform = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = React.useState(0);
  const [value, setValue] = React.useState([]);
  const [formData, setFormData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [auditordata, setAuditorData] = useState([]);

  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "",
  });

  const navigate = useNavigate();

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  useEffect(() => {
    getSubmitFormList();
  }, [sortConfig, search, page]);

  const getSubmitFormList = async () => {
    setFormData([]);

    let selectQuery = `?page=${page + 1}&limit=10&search=${search}&sortBy=${
      sortConfig.key
    }&sortFlag=${sortConfig.direction}&fromSubmittedDate=${
      formik1.values.fromSubmitDate
    }&toSubmittedDate=${formik1.values.toSubmitDate}&auditorId=${
      formik1.values.auditor
    }`;
    const res = await submittedFormList(selectQuery);
    if (res.status) {
      setFormData(res.data);
      setTotalData(res.total);
    } else {
      setFormData(null);
    }
  };

  const getDropdownData = async () => {
    let res = await AuditorDropdown();
    if (res?.status) {
      let data = res?.data?.map((item) => {
        return {
          id: item._id,
          name: item.firstName + " " + item.lastName,
        };
      });
      setAuditorData(data);
    } else {
      setAuditorData([]);
    }
  };

  const downloadExcel = async () => {
    const res = await submittedFormList();
    if (res.status) {
    } else {
    }
  };

  useEffect(() => {
    getDropdownData();
  }, []);

  const formik1 = useFormik({
    initialValues: submitformFilterInitialValues,
    onSubmit: async (values) => {
      setOpenDrawer(false);
      getSubmitFormList();
    },
  });

  return (
    <>
      <Heading head={"Submited Forms"} />

      <Stack gap={2} mt={1}>
        <Stack
          mb={1}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Search search={search} setSearch={setSearch} setPage={setPage} />
          <Stack
            flexDirection={"row"}
            // alignItems={"center"}
            gap={1}
            justifyContent={"flex-end"}
          >
            {/* <Button
              color="primary"
              // onClick={handleFilterClick}
              buttonName="Export to CSV"
              onClick={() => {
                downloadExcel()
              }}
            /> */}
            <Button
              buttonName={<VscFilter />}
              style={{ padding: "10px 11px" }}
              color="white"
              size="small"
              onClick={toggleDrawer}
              variant="contained"
              sx={{ mt: 2 }}
            />
          </Stack>
          <FilterSubmitform
            value={value}
            setValue={setValue}
            openDrawer={openDrawer}
            toggleDrawer={toggleDrawer}
            formik={formik1}
            auditordata={auditordata}
          />
        </Stack>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Title</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Auitor Name</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Device Name</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Device Id </div>
                  </Stack>
                </TableCell>

                <TableCell
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setSortConfig({
                      key: "submittedDate",
                      direction:
                        sortConfig &&
                        sortConfig.key === "submittedDate" &&
                        sortConfig.direction === "asc"
                          ? "desc"
                          : "asc",
                    })
                  }
                >
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>{"Submited-Date"}</div>
                    {sortConfig &&
                    sortConfig.key === "submittedDate" &&
                    sortConfig.direction === "asc" ? (
                      <IoIosArrowUp className="iconstyle" />
                    ) : (
                      <IoIosArrowDown />
                    )}
                  </Stack>
                </TableCell>

                <TableCell
                  style={{
                    cursor: "pointer",
                    textAlign: "right",
                  }}
                >
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>{"Action"}</div>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formData &&
                formData?.length > 0 &&
                formData?.map((data) => (
                  <TableRow
                    key={data.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(`/form-view`, { state: { id: data._id } });
                    }}
                  >
                    <TableCell
                      className="table-hover"
                      style={{ textAlign: "left" }}
                    >
                      {data?.fromId?.title}
                    </TableCell>
                    <TableCell style={{ textAlign: "left" }}>
                      {data.auditorId?.firstName +
                        " " +
                        data.auditorId?.lastName}
                    </TableCell>
                    <TableCell style={{ textAlign: "left" }}>
                      {data?.deviceName}
                    </TableCell>
                    <TableCell style={{ textAlign: "left" }}>
                      {data?.deviceId}
                    </TableCell>
                    <TableCell style={{ textAlign: "left" }}>
                      {moment(data?.submittedDate).format("DD-MM-YYYY hh:mm A")}
                    </TableCell>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <Stack
                        flexDirection={"row"}
                        gap={2}
                        alignItems={"center"}
                        justifyContent={"start"}
                        onClick={() => {
                          navigate(`/form-view`, { state: { id: data._id } });
                        }}
                      >
                        <BsEye style={{ fontSize: "20px" }} />
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              {formData == null ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="6"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : formData && formData.length == 0 ? (
                <TableRowsLoader colNumber={6} rowsNum={10} />
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Pagination
            count={10}
            shape="rounded"
            totalData={totalData}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </div>
      </Stack>
    </>
  );
};

export default Submitform;

import { deleteRequest, get, post, put } from "../web.request";


export const AdminDetail = async (id) => {
  return await get(`/adminDetail/${id}`);
}

export const AdminList = async (query) => {
  return await get(`/adminList${query}`)
}

export const AdminCreate = async (data) => {
  return await post(`/adminCreate `, data);
}

export const AdminUpdate = async (id, data) => {
  return await put(`/adminUpdate/${id}`, data);
}

export const AdminDelete = async (id) => {
  return await deleteRequest(`/admindelete/${id}`);
}

export const AdminStatusChange = async (id, data) => {
  return await put(`/adminStatusUpdate/${id}`, data);
}

export const ChnagePasswordOnMail = async (id) => {
  return await put(`/adminChangePassword/${id}`);
}

import React from "react";
import { Input } from "../../../components/Common";
import { Box, FormControl, Grid, MenuItem, Select, Slider, Stack, Tab, Tabs, Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import SkipLogic from "../SkipLogic";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Scale = ({
  index,
  forms,
  setForms,
  formIndex,
  getPayload,
  logicQuestionSelecteData, setLogicQuestionSelecteData,
  setDisplayLogicQuestionSelecteData,
  displaylogicQuestionSelecteData,
  skipLogicData,
  skipLogicCondition,
  setSkipLogicCondition, setDisplayLogicCondition,
  displayLogicCondition,
}) => {

  const handleChange = (event) => {
    let newArray = [...forms];
    let ids = uuidv4();
    newArray[index] = {
      ...getPayload(event.target.value),
      type: event.target.value,
      index: index,
      ids: ids,
      logicQuestionType: event.target.value == "text" ? "textEmail" : event.target.value,
    };
    setForms(newArray);

    let newArray1 = [...logicQuestionSelecteData];
    newArray1[index] = {
      ...getPayload(event.target.value),
      type: event.target.value,
      index: index,
      ids: ids,
      name: forms[index].question,
      value: "",
    };
    setLogicQuestionSelecteData(newArray1);

    let newArray2 = [...displaylogicQuestionSelecteData];
    newArray2[index] = {
      type: event.target.value,
      index: index,
      ids: ids,
      name: forms[index].question,
      value: "",
    };

    setDisplayLogicQuestionSelecteData(newArray2);
  };

  const [value, setValue] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Stack
      sx={{
        display: "flex",
        justifyContent: "space-between",
        gap: "10px",
        flexDirection: "row",
        width: "100%",
        marginBottom: "20px"
      }}
    >
      <Stack sx={{ width: "100%" }}>
        <Stack
          sx={{
            backgroundColor: "white.main",
            borderRadius: "7px",
            padding: "20px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            height: "fit-content"
          }}
        >
          <Stack sx={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <Typography
              fontSize={{ xs: "10px", sm: "16px" }}
              fontWeight={500}
              mb={"2px"}
              width={"10px"}
              display={"flex"}
              flexDirection={"column"}
            >
              {index + 1}.

            </Typography>
            <Stack
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "10px"
              }}
            >
              <textarea
                placeholder="Please Enter your Question text here..."
                className="defaultinput textareaformcreation"
                onChange={(e) => {
                  let newArray = [...forms];
                  newArray[index].question = e.target.value;
                  setForms(newArray);

                  let newArray1 = [...logicQuestionSelecteData];
                  newArray1[index].name = e.target.value;
                  setLogicQuestionSelecteData(newArray1);

                  let newArray2 = [...displaylogicQuestionSelecteData];
                  newArray2[index].name = e.target.value;
                  setDisplayLogicQuestionSelecteData(newArray
                  );
                }}
                value={forms[index].question}
              />
              <Slider defaultValue={50} aria-label="Default" valueLabelDisplay="auto" color="gray.main" />


              <Stack sx={{ display: "flex", flexDirection: "column" }} width={'100%'}>
                <Input
                  placeholder="Instruction Text"
                  style={{ width: "%" }}
                  onChange={(e) => {
                    let newArray = [...forms];
                    newArray[index].intructinText = e.target.value;
                    setForms(newArray);
                  }}
                  value={forms[index].intructinText}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
            className="formcard"
          >
            <Stack className="formcreationmain" >
              <Box className="headerrow">
                <Stack sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "20px", }} >
                  <FormControl>
                    <Select
                      value={forms[index].type}
                      onChange={handleChange}
                      displayEmpty
                      sx={{
                        width: 300,
                        ".MuiSelect-select": {
                          padding: "10px",
                        },
                      }}
                    >
                      <MenuItem value="" sx={{ display: "flex", gap: "10px" }}>
                        Select Question Type
                      </MenuItem>
                      <MenuItem value="text" sx={{ display: "flex", gap: "10px" }}>
                        Text
                      </MenuItem>
                      <MenuItem
                        value="multiSelect"
                        sx={{ display: "flex", gap: "10px" }}
                      >
                        {" "}
                        Multiple Choice
                      </MenuItem>
                      <MenuItem value="scale" sx={{ display: "flex", gap: "10px" }}>
                        {" "}
                        Scale
                      </MenuItem>
                      <MenuItem
                        value="DropDown"
                        sx={{ display: "flex", gap: "10px" }}
                      >
                        {" "}
                        Drop Down
                      </MenuItem>
                      <MenuItem value="Metrix" sx={{ display: "flex", gap: "10px" }}>
                        {" "}
                        Metrix
                      </MenuItem>
                      <MenuItem value="Scan" sx={{ display: "flex", gap: "10px" }}>
                        Media Capture
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Box sx={{ width: "fit-content" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={value}
                        onChange={handleChange1}
                        aria-label="basic tabs example"
                      >
                        <Tab label="Options" {...a11yProps(0)} />
                        <Tab label="Logic" {...a11yProps(1)} />
                      </Tabs>
                    </Box>
                  </Box>
                </Stack>
                <label style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    style={{
                      marginRight: "10px",
                      height: "17px",
                      width: "17px"
                    }}
                    onChange={(e) => {
                      let newArray = [...forms];
                      newArray[index].answerRequired = e.target.checked;
                      setForms(newArray);
                    }}
                    checked={forms[index].answerRequired}
                  />
                  Answer Required
                </label>
              </Box>
            </Stack>
            <div className="verticalline"></div>
            <CustomTabPanel value={value} index={0}>
              <Stack spacing={2}>
                <Box style={{ display: "flex", gap: "50px", alignItems: "start" }}>
                  <Stack
                    sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
                  >
                    <Box>
                      <Typography
                        fontSize={{ xs: "10px", sm: "13px" }}
                        fontWeight={500}
                        mb={"2px"}
                      >
                        Left Lable:
                      </Typography>
                      <Input
                        placeholder="Enter Laft Lable"
                        style={{ width: "300px", marginBottom: "10px" }}
                        onChange={(e) => {
                          let newArray = [...forms];
                          newArray[index].leftLable = e.target.value;
                          setForms(newArray);
                        }}
                        value={forms[index].leftLable}
                      />
                    </Box>
                    <Box>
                      <Typography
                        fontSize={{ xs: "10px", sm: "13px" }}
                        fontWeight={500}
                        mb={"2px"}
                      >
                        Right Lable:
                      </Typography>
                      <Input
                        placeholder="Enter Right Lable"
                        style={{ width: "300px", marginBottom: "10px" }}
                        onChange={(e) => {
                          let newArray = [...forms];
                          newArray[index].rightLable = e.target.value;
                          setForms(newArray);
                        }}
                        value={forms[index].rightLable}
                      />
                    </Box>
                  </Stack>
                  <Stack sx={{ display: "flex", justifyContent: "center" }}>

                    <Typography fontSize={{ sx: "10px", xs: "13px" }} style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="checkbox"
                        style={{
                          marginRight: "10px",
                          height: "17px",
                          width: "17px"
                        }}
                        onChange={(e) => {
                          let newArray = [...forms];
                          newArray[index].displaySliderValuePercentage = e.target.checked;
                          newArray[index].minRange = 0;
                          newArray[index].maxRange = 100;
                          setForms(newArray);
                        }}
                        checked={forms[index].displaySliderValuePercentage}
                      />
                      Display Slider Value As percentage
                    </Typography>
                  </Stack>
                </Box>
                {!forms[index].displaySliderValuePercentage && (
                  <Box style={{ display: "flex", gap: "50px", alignItems: "start" }}>
                    <Stack
                      sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
                    >
                      <Box>
                        <Typography
                          fontSize={{ xs: "10px", sm: "13px" }}
                          fontWeight={500}
                          mb={"2px"}
                        >
                          Min Range:
                        </Typography>
                        <Input
                          placeholder="Min"
                          type="number"
                          value={forms[index].minRange}
                          onChange={(e) => {
                            let newArray = [...forms];
                            newArray[formIndex].minRange = e.target.value;
                            setForms(newArray);
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          fontSize={{ xs: "10px", sm: "13px" }}
                          fontWeight={500}
                          mb={"2px"}
                        >
                          Max Range:
                        </Typography>
                        <Input
                          placeholder="Max"
                          type="number"
                          value={forms[index].maxRange}
                          onChange={(e) => {
                            let newArray = [...forms];
                            newArray[formIndex].maxRange = e.target.value;
                            setForms(newArray);
                          }}
                        />
                      </Box>
                    </Stack>
                  </Box>
                )}

              </Stack>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Stack spacing={2}>

                <SkipLogic logicQuestionSelecteData={logicQuestionSelecteData} forms={forms} setForms={setForms} index={index} setLogicQuestionSelecteData={setLogicQuestionSelecteData} skipLogicData={skipLogicData} skipLogicCondition={skipLogicCondition} setSkipLogicCondition={setSkipLogicCondition} displaylogicQuestionSelecteData={displaylogicQuestionSelecteData} displayLogicCondition={displayLogicCondition} setDisplayLogicCondition={setDisplayLogicCondition} setDisplayLogicQuestionSelecteData={setDisplayLogicQuestionSelecteData} />

              </Stack>
            </CustomTabPanel>
          </Stack>

        </Stack>
      </Stack>
    </Stack>
  );
}


export default Scale;

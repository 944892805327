import React from 'react'
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

const SkipLogic = ({ logicQuestionSelecteData, forms, setForms, index, skipLogicData, skipLogicCondition, setSkipLogicCondition,
  displaylogicQuestionSelecteData, setDisplaylogicQuestionSelecteData,
  setDisplayLogicCondition, displayLogicCondition
}) => {

  console.log("displaylogicQuestionSelecteData", displaylogicQuestionSelecteData)
  return (
    <>
      <Box>
        <span className="boldfont">DISPLAY LOGIC</span>
        <Box
          className="logictab"
        >
          <Typography>Display the Question Only</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              flexDirection: "row",
              textTransform: "capitalize",
              gap: 1,
            }}
          >
            <span style={{ fontSize: "16px" }}>If</span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "10px",
              }}
            >
              <Grid container spacing={2}>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Select placeholder="yuyuyu" onChange={(e) => {
                      let newArray = [...forms];
                      newArray[index].dlogicIndex = e.target.value;
                      newArray[index].dlogicQuestionType = logicQuestionSelecteData[e.target.value].type == "text" ? "textEmail" : logicQuestionSelecteData[e.target.value].type;
                      setForms(newArray);

                      let newArray2 = [...displayLogicCondition];
                      newArray2[index].questionIndex = e.target.value;
                      newArray2[index].questionType = logicQuestionSelecteData[e.target.value].type;
                      newArray2[index].value = "";
                      setDisplayLogicCondition(newArray2);
                    }}
                      value={forms[index].dlogicIndex}
                    >
                      {displaylogicQuestionSelecteData?.map((item, i) => (
                        index > i &&
                        <MenuItem key={i} value={i}>
                          Q{item.index + 1} - <span
                            style={{ color: "#9F9F9F" }}
                          >{item.name}</span>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

              </Grid>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <Select
                      onChange={(e) => {
                        let newArray = [...forms];
                        newArray[index].displayLogicCondition = e.target.value;
                        setForms(newArray);

                        let newArray2 = [...displayLogicCondition];
                        newArray2[index].condition = e.target.value;
                        newArray2[index].displayLogicValue = "";

                        setDisplayLogicCondition(newArray2);
                      }}
                      value={forms[index].displayLogicCondition}
                    >
                      {skipLogicData
                        && Array.isArray(skipLogicData)
                        && skipLogicData.length > 0
                        && forms[index]
                        && forms[index].logicQuestionType
                        && skipLogicData.some(item => item.type == forms[index].dlogicQuestionType)
                        ? skipLogicData
                          .filter(item => item.type === forms[index].dlogicQuestionType)[0]?.data
                          .map((item, i) => (
                            <MenuItem key={i} value={item.value}>{item.name}</MenuItem>
                          ))
                        : null}

                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    {forms[index].displayLogicCondition === "Starts with" || forms[index].displayLogicCondition === "Ends with" || forms[index].displayLogicCondition === "Contains" || forms[index].displayLogicCondition === "Does not contain" || forms[index].displayLogicCondition === "Equal to" || forms[index].displayLogicCondition === "Not equal to" || forms[index].displayLogicCondition === "Less than" || forms[index].displayLogicCondition === "Greater than" ?
                      <TextField
                        type="text"
                        onChange={(e) => {
                          let newArray = [...forms];
                          newArray[index].displayLogicValue = e.target.value;
                          setForms(newArray);

                          let newArray2 = [...displayLogicCondition];
                          newArray2[index].value = e.target.value;
                          setDisplayLogicCondition(newArray2);
                        }}
                        value={forms[index].displayLogicValue}
                        placeholder=""
                      />
                      : forms[index].displayLogicCondition === "Responded selected" || forms[index].displayLogicCondition === "Responded Not selected" ?
                        <Select placeholder="yuyuyu" onChange={(e) => {
                          let newArray = [...forms];
                          newArray[index].displayLogicValue = e.target.value;
                          setForms(newArray);

                          let newArray2 = [...displayLogicCondition];
                          newArray2[index].value = e.target.value;
                          setDisplayLogicCondition(newArray2);
                        }}
                          value={forms[index].displayLogicValue}
                        >
                          {forms[forms[index]?.dlogicIndex]?.data?.map((item, i) => (
                            <MenuItem key={i} value={item?.value}>
                              {item.value}
                            </MenuItem>
                          ))}
                        </Select>
                        : null}
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </Box>
        </Box>
      </Box>
      <Box>
        <span className="boldfont">SKIP LOGIC</span>
        <Box
          className="logictab"
        >
          <Typography>When answering this Questions</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              flexDirection: "row",
              textTransform: "capitalize",
              gap: 1,
            }}
          >
            <span style={{ fontSize: "16px" }}>If</span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "10px",
              }}
            >
              <Grid container spacing={2}>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Select placeholder="yuyuyu" onChange={(e) => {
                      let newArray = [...forms];
                      newArray[index].logicIndex = e.target.value;
                      newArray[index].logicQuestionType = logicQuestionSelecteData[e.target.value].type == "text" ? "textEmail" : logicQuestionSelecteData[e.target.value].type;
                      setForms(newArray);

                      let newArray2 = [...skipLogicCondition];
                      newArray2[index].questionIndex = e.target.value;
                      newArray2[index].questionType = logicQuestionSelecteData[e.target.value].type;
                      newArray2[index].value = "";

                      setSkipLogicCondition(newArray2);
                    }}
                      value={forms[index].logicIndex}
                    >
                      {logicQuestionSelecteData?.map((item, i) => (
                        index >= i &&
                        <MenuItem key={i} value={i}>
                          Q{item.index + 1} - <span
                            style={{ color: "#9F9F9F" }}
                          >{item.name}</span>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <Select
                      onChange={(e) => {
                        let newArray = [...forms];
                        newArray[index].skipLogicCondition = e.target.value;
                        setForms(newArray);

                        let newArray2 = [...skipLogicCondition];
                        newArray2[index].condition = e.target.value;
                        newArray2[index].value = "";

                        setSkipLogicCondition(newArray2);
                      }}
                      value={forms[index].skipLogicCondition}
                    >
                      {skipLogicData
                        && Array.isArray(skipLogicData)
                        && skipLogicData.length > 0
                        && forms[index]
                        && forms[index].logicQuestionType
                        && skipLogicData.some(item => item.type == forms[index].logicQuestionType)
                        ? skipLogicData
                          .filter(item => item.type === forms[index].logicQuestionType)[0]?.data
                          .map((item, i) => (
                            <MenuItem key={i} value={item.value}>{item.name}</MenuItem>
                          ))
                        : null}

                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    {forms[index].skipLogicCondition === "Starts with" || forms[index].skipLogicCondition === "Ends with" || forms[index].skipLogicCondition === "Contains" || forms[index].skipLogicCondition === "Does not contain" || forms[index].skipLogicCondition === "Equal to" || forms[index].skipLogicCondition === "Not equal to" || forms[index].skipLogicCondition === "Less than" || forms[index].skipLogicCondition === "Greater than" ?
                      <TextField
                        type="text"
                        onChange={(e) => {
                          let newArray = [...forms];
                          newArray[index].skipLogicValue = e.target.value;
                          setForms(newArray);

                          let newArray2 = [...skipLogicCondition];
                          newArray2[index].value = e.target.value;
                          setSkipLogicCondition(newArray2);
                        }}
                        value={forms[index].skipLogicValue}
                        placeholder=""
                      />
                      : forms[index].skipLogicCondition === "Responded selected" || forms[index].skipLogicCondition === "Responded Not selected" ?
                        <Select placeholder="yuyuyu" onChange={(e) => {
                          let newArray = [...forms];
                          newArray[index].skipLogicValue = e.target.value;
                          setForms(newArray);

                          let newArray2 = [...skipLogicCondition];
                          newArray2[index].value = e.target.value;
                          setSkipLogicCondition(newArray2);
                        }}
                          value={forms[index].value}
                        >
                          {forms[forms[index]?.logicIndex]?.data?.map((item, i) => (
                            <MenuItem key={i} value={item?.value}>
                              {item.value}
                            </MenuItem>
                          ))}
                        </Select>
                        : null}
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            backgroundColor: "gray.main",
            padding: "10px",
            borderRadius: "10px",
            width: "100%",
            alignItems: "center",
            gap: "10px"
          }}
        >
          <span
            className="boldfont" style={{ color: "#fff" }}
          >
            Then Jump To
          </span>
          <FormControl style={{ flex: 2 }}>
            <Select fullWidth
              onChange={(e) => {
                let newArray = [...forms];
                newArray[index].skipLogicJump = e.target.value;
                setForms(newArray);

                let newArray2 = [...skipLogicCondition];
                newArray2[index].jumpTo = e.target.value;
                setSkipLogicCondition(newArray2);
              }}
              value={forms[index].skipLogicJump}
            >
              {
                forms.map((form, i) => (
                  forms[index].logicIndex < i &&
                  < MenuItem key={i} value={i} > Q-{i + 1}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Box>
      </Box>
    </>

  )
}

export default SkipLogic
import axios from "axios";

axios.defaults.withCredentials = false;
const AxiosWithoutToken = axios.create({
  baseURL: process.env.REACT_APP_API_URL,

  crossDomain: false,
});
  console.log(AxiosWithoutToken);


export default AxiosWithoutToken;




export const loginInitialValues = {
  email: "",
  password: "",
  rememberMe: false,
};

export const addAdminInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  role: "",
};

export const filterAdminInitialValues = {
  status: "",
  role: "",
};

export const roleInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  role: "",
};

export const filterRoleInitialValues = {
  status: "",
};

export const roleAddInitialValues = {
  roleName: "",
};

export const addAuditorInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  location: "",
}

export const addFormInitialValues = {
  title: "",
  location: "",
  notes: "",
  questions: [],
  expireDate: null,

};

export const filterAuditorInitialValues = {
  status: "",
  email: "",
  mobile: ""
};

export const groupInitialValues = {
  groupName: "",
  auditors: "",
};

export const formFilterInitialValues = {
  fromExpireDate: "",
  toExpireDate: "",
  fromPublishedDate: "",
  toPublishedDate: "",
  status: "",
};

export const submitformFilterInitialValues = {
  fromSubmitDate: "",
  toSubmitDate: "",
  auditor: ""
};

export const filterGroupInitialValues = {
  status: "",
};

export const assignFormInitialValues = {
  groups: [],
};

export const extendFormInitialValues = {
  extendDate: null,
};

import React from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CloseIcon } from "../../../helper/Icons";
import { Button } from "..";

const Uploadimagemultiselect = ({ handleClose, modalOpen, handelClick }) => {
  return (
    <div>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Select Image
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        {/* <CloseIcon /> */}
      </IconButton>
      <DialogContent>
        <Box width={{ sm: "400px" }}>
          <input
            type="file" />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName={"Cancel"}
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button buttonName={"Submit"} size="small" onClick={handleClose} />
      </DialogActions>
    </div>
  );
};

export default Uploadimagemultiselect;

import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { AddAuditors, Addgroup, AuditorsChangePassword, ChangePassword, Delete, EditFormInformation, ExtendForm, Logout } from './modals';
import DeleteAuditorinfo from './modals/DeleteAuditorinfo';
import Addadmin from './modals/Addadmin';
import AssignForm from './modals/AssignForm';
import Uploadimagemultiselect from './modals/Uploadimage';

export default function CommonModal({ handleClose, modalOpen, handelClick, data, formik }) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Common_modal"
      open={modalOpen.open}
      className={modalOpen.class}
    >
      {modalOpen.currentComponent === 'changePassword' && (
        <ChangePassword handleClose={handleClose} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'addauditor' && (
        <AddAuditors data={data}
          handleClose={handleClose}
          formik={formik}
          modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'auditorchangepass' && (
        <AuditorsChangePassword handleClose={handleClose} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'deleteinfoauditors' && (
        <DeleteAuditorinfo handleClose={handleClose} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'extend' && (
        <ExtendForm handleClose={handleClose} modalOpen={modalOpen} formik={formik} />
      )}
      {['delete', 'status', 'changepassword'].includes(modalOpen.currentComponent) && (
        <Delete
          handleClose={handleClose}
          modalOpen={modalOpen}
          handelClick={handelClick}
        />
      )}
      {modalOpen.currentComponent === 'logout' && (
        <Logout handleClose={handleClose} handelClick={handelClick} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'Uploadimagemultiselect' && (
        <Uploadimagemultiselect handleClose={handleClose} handelClick={handelClick} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'addgroup' && (
        <Addgroup
          data={data}
          handleClose={handleClose}
          formik={formik}
          modalOpen={modalOpen}
        />
      )}
      {modalOpen.currentComponent === 'assignForm' && (
        <AssignForm
          data={data}
          handleClose={handleClose}
          formik={formik}
          modalOpen={modalOpen}
        />
      )}
      {modalOpen.currentComponent === 'addadmin' && (
        <Addadmin
          data={data}
          handleClose={handleClose}
          formik={formik}
          modalOpen={modalOpen} />
      )}
      {
        modalOpen.currentComponent === 'editform' && (
          <EditFormInformation handleClose={handleClose} handelClick={handelClick} modalOpen={modalOpen} />
        )
      }

    </Dialog>
  );
}

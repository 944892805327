import React from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons";
import { Button, Input } from "../../Common";

const AddAuditors = ({ handleClose, formik, data, modalOpen }) => {
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.isEdit ? 'Edit' : 'Create'} Auditors
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container gap={2} maxWidth={{ sm: "400px" }} paddingY={1}>
          <div style={{ display: "flex", width: "100%", gap: "10px" }}>
            <Grid item xs={12}>
              <Input
                labelinput={"First Name"}
                formik={formik}
                name='firstName'
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                labelinput={"Last Name"}
                formik={formik}
                name='lastName'
              />
            </Grid>
          </div>
          <div style={{ display: "flex", width: "100%", gap: "10px" }}>
            <Grid item xs={12}>
              <Input
                labelinput={"Mobile"}
                formik={formik}
                name="mobile"
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                labelinput={"Email"}
                name="email"
                formik={formik}
              />
            </Grid>
          </div>
          <Grid item xs={12}>
            <Input
              labelinput={"Current Location"}
              formik={formik}
              name="location"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName={"Cancel"}
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button buttonName={modalOpen.isEdit ? 'Save' : 'Create'} size='small' onClick={formik.handleSubmit} />
      </DialogActions>
    </>
  );
};

export default AddAuditors;

import * as Yup from "yup";

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address.")
    .required("Email is required."),
  password: Yup.string()
    .required("Password is required.")
    .min(8, "Password must be at least 8 characters."),
});


export const changePasswordValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("New Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
      "Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character."
    ),
  confirmpass: Yup.string()
    .required("Confirm Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
      "Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character."
    ),
  currentPassword: Yup.string().required("Password is required."),
});

export const addAdminValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address.")
    .required("Email is required."),
  role: Yup.string().required("Role is required."),
  firstName: Yup.string().required("First Name is required."),
  lastName: Yup.string().required("Last Name is required."),
});

export const addAuditorValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address.")
    .required("Email is required."),
  mobile: Yup.string().required("Mobile is required."),
  location: Yup.string().required("Location is required."),
  firstName: Yup.string().required("First Name is required."),
  lastName: Yup.string().required("Last Name is required."),
});

export const addFormValidationSchema = Yup.object().shape({
  title: Yup.string().required("Form Name is required."),
  expireDate: Yup.string().required("Expires Date is required."),
});

export const roleAddValidationSchema = Yup.object().shape({
  roleName: Yup.string().required("Role Name is required."),
});

export const profileUpdateValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required."),
  lastName: Yup.string().required("Last Name is required."),
  email: Yup.string()
    .email("Invalid email address.")
    .required("Email is required."),
});

export const addgroupValidationSchema = Yup.object().shape({
  groupName: Yup.string().required("Group Name is required."),
  auditors: Yup.array().required("Auditors is required."),
});


export const formAssignValidationSchema = Yup.object().shape({
  groups: Yup.array().required("Groups is required."),
});

export const extendFormValidationSchema = Yup.object().shape({
  extendDate: Yup.string().required("Date is required."),
});

import React from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons";
import { Button, Input, Select } from "../../Common";

const Addadmin = ({ handleClose, formik, data, modalOpen }) => {
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.isEdit ? 'Edit' : 'Create'} Admin
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container gap={2} maxWidth={{ sm: "400px" }} paddingY={1}>
          <div style={{ display: "flex", width: "100%", gap: "10px" }}>
            <Grid item xs={12}>
              <Input
                name='firstName'
                formik={formik}
                labelinput={"First Name"}
                maxLength={50}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name='lastName'
                formik={formik}
                labelinput={"Last Name"}
                maxLength={50}
              />
            </Grid>
          </div>
          <div style={{ display: "flex", width: "100%", gap: "10px" }}>
            <Grid item xs={12}>
              <Select
                labelinput='Role'
                name='role'
                selectHead='None'
                formik={formik}
                value={formik.values.role}
                onChange={(e) => {
                  formik.setFieldValue('role', e.target.value);
                }}
                selectList={data}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name='email'
                formik={formik}
                disabled={modalOpen.isEdit}
                labelinput={"Email"}
                maxLength={100}
              />
            </Grid>
          </div>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName={"Cancel"}
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button buttonName={modalOpen.isEdit ? 'Save' : 'Create'} size='small' onClick={formik.handleSubmit} />
      </DialogActions>
    </>
  );
};

export default Addadmin;

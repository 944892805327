import React, { useRef, useState } from "react";
import { Button, Heading, Input } from "../../../components/Common";
import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { HiOutlineTrash } from "react-icons/hi";
import { v4 as uuidv4 } from "uuid";
import Papa, { parse } from "papaparse";
import PropTypes from "prop-types";
import SkipLogic from "../SkipLogic";

///
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
////
const DropDownForm = ({
  index,
  formIndex,
  forms,
  setForms,
  getPayload,
  logicQuestionSelecteData,
  setLogicQuestionSelecteData,
  setDisplayLogicQuestionSelecteData,
  displaylogicQuestionSelecteData,
  skipLogicData,
  skipLogicCondition,
  setSkipLogicCondition,
  setDisplayLogicCondition,
  displayLogicCondition,
}) => {
  const fileInputRef = useRef(null);

  const randomId = () => {
    return uuidv4();
  };

  const handleAddRating = () => {
    let newArray = [...forms];
    newArray[formIndex].data.push({ ids: randomId(), value: "" });
    setForms(newArray);
  };

  const handleDeleteRating = (id) => {
    let newArray = [...forms];
    newArray[formIndex].data = newArray[formIndex].data.filter(
      (rating) => rating.ids !== id
    );
    if (newArray[formIndex].data.length === 0) {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      newArray[formIndex].file = "";
    }
    setForms(newArray);
  };

  const handleRating = (id, value) => {
    let newArray = [...forms];
    newArray[formIndex].data = newArray[formIndex].data.map((rating) =>
      rating.ids === id ? { ...rating, value } : rating
    );

    setForms(newArray);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    Papa.parse(file, {
      complete: (results) => {
        const [header, ...data] = results.data;

        let dataIndex = header.indexOf("data");
        let latIndex = header.indexOf("lat");
        let longIndex = header.indexOf("long");

        console.log("header", dataIndex, header);

        const parsedData = results.data.slice(1).map((row) => ({
          ids: uuidv4(),
          value: row[dataIndex] ? row[dataIndex] : "",
          file: true,
          lat: row[latIndex] ? row[latIndex] : "",
          long: row[longIndex] ? row[longIndex] : "",
        }));
        let newArray = [...forms];
        if (
          newArray[formIndex].data &&
          newArray[formIndex].data[0].value === ""
        ) {
          newArray[formIndex].data = parsedData;
        } else {
          newArray[formIndex].data = [
            ...newArray[formIndex].data,
            ...parsedData,
          ];
        }
        newArray[formIndex].file = event.target.files[0].name;
        setForms(newArray);
      },
      header: false,
    });
  };

  const handleFileRemove = () => {
    let newArray = [...forms];
    newArray[formIndex].file = "";
    newArray[formIndex].data = newArray[formIndex].data.filter(
      (answer) => !answer.file
    );
    newArray[formIndex].defaultAnswer = [];

    setForms(newArray);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleChange = (event) => {
    let newArray = [...forms];

    let ids = uuidv4();

    newArray[formIndex] = {
      ...getPayload(event.target.value),
      type: event.target.value,
      index: index,
      logicQuestionType:
        event.target.value == "text" ? "textEmail" : event.target.value,
      ids: ids,
    };
    setForms(newArray);
    let newArray1 = [...logicQuestionSelecteData];
    newArray1[index] = {
      ...getPayload(event.target.value),
      type: event.target.value,
      index: index,
      ids: ids,
      name: forms[index].question,
      value: "",
    };
    setLogicQuestionSelecteData(newArray1);

    let newArray2 = [...displaylogicQuestionSelecteData];
    newArray2[index] = {
      type: event.target.value,
      index: index,
      ids: ids,
      name: forms[index].question,
      value: "",
    };
    setDisplayLogicQuestionSelecteData(newArray2);
  };

  //////////
  const [value, setValue] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };
  const [anchorElAdd, setAnchorElAdd] = React.useState(null);
  const [anchorElRemove, setAnchorElRemove] = React.useState(null);

  const openAdd = Boolean(anchorElAdd);
  const openRemove = Boolean(anchorElRemove);
  const idAdd = openAdd ? "add-popover" : undefined;
  const idRemove = openRemove ? "remove-popover" : undefined;

  const handleCloseRemove = () => {
    setAnchorElRemove(null);
  };

  console.log("formscdsdsdssdsd", forms);

  return (
    <Stack
      sx={{
        display: "flex",
        justifyContent: "space-between",
        gap: "10px",
        flexDirection: "row",
        width: "100%",
        marginBottom: "20px",
      }}
    >
      <Stack sx={{ width: "100%" }}>
        <Stack
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "100%",
            height: "fit-content",
          }}
        >
          <Stack
            sx={{
              backgroundColor: "white.main",
              borderRadius: "7px",
              padding: "20px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              height: "fit-content",

              gap: "10px",
            }}
          >
            <Stack sx={{ display: "flex", flexDirection: "row", gap: "20px" }}>
              <Typography
                fontSize={{ xs: "10px", sm: "16px" }}
                fontWeight={500}
                mb={"2px"}
                width={"10px"}
                display={"flex"}
                flexDirection={"column"}
              >
                {formIndex + 1}.
              </Typography>
              <Stack
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <textarea
                  placeholder="Please Enter your Question text here..."
                  className="defaultinput textareaformcreation"
                  onChange={(e) => {
                    let newArray = [...forms];
                    newArray[formIndex].question = e.target.value;
                    setForms(newArray);

                    let newArray1 = [...logicQuestionSelecteData];
                    newArray1[index].name = e.target.value;
                    setLogicQuestionSelecteData(newArray1);

                    let newArray2 = [...displaylogicQuestionSelecteData];
                    newArray2[index].name = e.target.value;
                    setDisplayLogicQuestionSelecteData(newArray2);
                  }}
                  value={forms[formIndex].question}
                />
                <select className="defaultinput">
                  <option>Select Answer</option>
                </select>

                <Stack
                  sx={{ display: "flex", flexDirection: "column" }}
                  width={"100%"}
                >
                  <Input
                    placeholder="Instruction Text"
                    onChange={(e) => {
                      let newArray = [...forms];
                      newArray[formIndex].intructinText = e.target.value;
                      setForms(newArray);
                    }}
                    value={forms[formIndex].intructinText}
                  />
                </Stack>
              </Stack>
            </Stack>

            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
              className="formcard"
            >
              <Stack className="formcreationmain">
                <Box className="headerrow">
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <FormControl>
                      <Select
                        value={forms[formIndex].type}
                        onChange={handleChange}
                        displayEmpty
                        sx={{
                          width: 300,
                          ".MuiSelect-select": {
                            padding: "10px",
                          },
                        }}
                      >
                        <MenuItem
                          value=""
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          Select Question Type
                        </MenuItem>
                        <MenuItem
                          value="text"
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          Text
                        </MenuItem>
                        <MenuItem
                          value="multiSelect"
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          {" "}
                          Multiple Choice
                        </MenuItem>
                        <MenuItem
                          value="scale"
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          {" "}
                          Scale
                        </MenuItem>
                        <MenuItem
                          value="DropDown"
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          {" "}
                          Drop Down
                        </MenuItem>
                        <MenuItem
                          value="Metrix"
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          {" "}
                          Metrix
                        </MenuItem>
                        <MenuItem
                          value="Scan"
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          Media Capture
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <Box sx={{ width: "fit-content" }}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={value}
                          onChange={handleChange1}
                          aria-label="basic tabs example"
                        >
                          <Tab label="Options" {...a11yProps(0)} />
                          <Tab label="Logic" {...a11yProps(1)} />
                        </Tabs>
                      </Box>
                    </Box>
                  </Stack>
                  <label style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      style={{
                        marginRight: "10px",
                        height: "17px",
                        width: "17px",
                      }}
                      onChange={(e) => {
                        let newArray = [...forms];
                        newArray[formIndex].answerRequired = e.target.checked;
                        setForms(newArray);
                      }}
                      checked={forms[formIndex].answerRequired}
                    />
                    Answer Required
                  </label>
                </Box>
              </Stack>
              <div className="verticalline"></div>
              {/* body */}
              <CustomTabPanel value={value} index={0}>
                <Stack spacing={2}>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "start",
                      width: "100%",
                    }}
                  >
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "30px",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          justifyContent: "flex-start",
                          width: "100%",
                        }}
                      >
                        <Typography
                          fontSize={{ xs: "10px", sm: "16px" }}
                          fontWeight={500}
                          mb={"2px"}
                          width={"150px"}
                        >
                          Type:
                        </Typography>
                        <FormControl sx={{ width: "250px", display: "flex" }}>
                          <Select
                            labelId="demo-simple  -select-label"
                            id="demo-simple-select"
                            displayEmpty
                            onChange={(e) => {
                              let newArray = [...forms];
                              newArray[formIndex].answerType = e.target.value;
                              setForms(newArray);
                              if (e.target.value === "single") {
                                let newArray = [...forms];
                                newArray[formIndex].selectionLimit = "";
                                newArray[formIndex].minRange = "";
                                newArray[formIndex].maxRange = "";
                                newArray[formIndex].exactNumber = "";
                                newArray[formIndex].defaultAnswer = [];
                                setForms(newArray);
                              }
                            }}
                            value={forms[formIndex].answerType}
                          >
                            <MenuItem value="multiple">Multiple</MenuItem>
                            <MenuItem value="single">Single</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            width: "100%",
                            gap: "10px",
                          }}
                        >
                          <label
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <input
                              type="checkbox"
                              style={{
                                marginRight: "10px",
                                height: "17px",
                                width: "17px",
                              }}
                              onChange={(e) => {
                                let newArray = [...forms];
                                newArray[formIndex].randomize =
                                  e.target.checked;
                                setForms(newArray);
                              }}
                              checked={forms[formIndex].randomize}
                            />
                            Randomize
                          </label>
                          <label
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <input
                              type="checkbox"
                              style={{
                                marginRight: "10px",
                                height: "17px",
                                width: "17px",
                              }}
                              onChange={(e) => {
                                let newArray = [...forms];
                                newArray[formIndex].otherOption =
                                  e.target.checked;
                                setForms(newArray);
                              }}
                              checked={forms[formIndex].otherOption}
                            />
                            Allow Other Option
                          </label>
                        </Box>
                      </Box>
                    </Stack>
                  </Box>
                  <Stack
                    sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
                  >
                    {forms[formIndex].answerType === "multiple" && (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          fontSize={{ xs: "10px", sm: "16px" }}
                          fontWeight={500}
                          mb={"2px"}
                          width={"150px"}
                        >
                          Selection Limits:
                        </Typography>
                        <FormControl sx={{ width: "250px", display: "flex" }}>
                          <Select
                            labelId="demo-simple  -select-label"
                            id="demo-simple-select"
                            displayEmpty
                            onChange={(e) => {
                              let newArray = [...forms];
                              newArray[formIndex].selectionLimit =
                                e.target.value;
                              newArray[formIndex].minRange = 0;
                              newArray[formIndex].maxRange = 0;
                              newArray[formIndex].exactNumber = "";
                              setForms(newArray);
                            }}
                            value={forms[formIndex].selectionLimit}
                            defaultValue={"unlimited"}
                          >
                            <MenuItem value="unlimited">Unlimited</MenuItem>
                            <MenuItem value="range">Range</MenuItem>
                            <MenuItem value="exactNumber">
                              Exact Number
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                    {forms[formIndex].selectionLimit === "range" && (
                      <>
                        <Input
                          placeholder="Min"
                          type="number"
                          value={forms[formIndex].minRange}
                          onChange={(e) => {
                            let newArray = [...forms];
                            newArray[formIndex].minRange = parseInt(
                              e.target.value
                            );
                            setForms(newArray);
                          }}
                          label={"Min"}
                        />
                        <Input
                          placeholder="Max"
                          type="number"
                          value={forms[formIndex].maxRange}
                          onChange={(e) => {
                            let newArray = [...forms];
                            newArray[formIndex].maxRange = parseInt(
                              e.target.value
                            );
                            setForms(newArray);
                          }}
                          label={"Max"}
                        />
                      </>
                    )}
                    {forms[formIndex].selectionLimit === "exactNumber" && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Typography
                          fontSize={{ xs: "10px", sm: "16px" }}
                          fontWeight={500}
                          mb={"2px"}
                        >
                          Exact Number
                        </Typography>
                        <FormControl sx={{ width: "250px", display: "flex" }}>
                          <Select
                            labelId="demo-simple  -select-label"
                            id="demo-simple-select"
                            displayEmpty
                            onChange={(e) => {
                              let newArray = [...forms];
                              newArray[formIndex].exactNumber = e.target.value;
                              setForms(newArray);
                            }}
                            value={forms[formIndex].exactNumber}
                          >
                            <MenuItem value="">Select Exact Number</MenuItem>
                            {forms[formIndex].data.map((answer, index) => (
                              <MenuItem key={answer.ids} value={index + 1}>
                                {index + 1}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                  </Stack>
                  <Divider />
                  <Stack
                    sx={{ display: "flex", flexDirection: "row", gap: "20px" }}
                  >
                    <Typography
                      fontSize={{ xs: "10px", sm: "16px" }}
                      fontWeight={500}
                      mb={"2px"}
                      width={"140px"}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      Default Answer:
                    </Typography>

                    {forms[formIndex].answerType === "single" ? (
                      <FormControl sx={{ width: "250px", display: "flex" }}>
                        <Select
                          labelId="demo-simple  -select-label"
                          id="demo-simple-select"
                          displayEmpty
                          onChange={(e) => {
                            let newArray = [...forms];
                            newArray[formIndex].defaultAnswer = [
                              e.target.value,
                            ];
                            setForms(newArray);
                          }}
                          value={forms[formIndex].defaultAnswer[0]}
                        >
                          <MenuItem value="">Select Default Answer</MenuItem>
                          {forms[formIndex].data.map((answer, index) => (
                            <MenuItem key={answer.ids} value={answer.ids}>
                              {answer.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={forms[formIndex].data.filter(
                          (ans) =>
                            ans.value != "" &&
                            !forms[formIndex].defaultAnswer.includes(ans.ids)
                        )}
                        getOptionLabel={(option) =>
                          option.value != "" ? option.value : ""
                        }
                        filterSelectedOptions
                        onChange={(e, value) => {
                          let newArray = [...forms];
                          newArray[formIndex].defaultAnswer = value.map(
                            (val) => val.ids
                          );
                          setForms(newArray);
                        }}
                        value={forms[formIndex].defaultAnswer.map((ans) => {
                          return forms[formIndex].data.find(
                            (data) => data.ids === ans
                          );
                        })}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Default Answer"
                            style={{ width: "350px" }}
                          />
                        )}
                      />
                    )}
                  </Stack>
                  <Divider />
                  <Stack
                    sx={{ display: "flex", flexDirection: "row", gap: "20px" }}
                  >
                    <Typography
                      fontSize={{ xs: "10px", sm: "16px" }}
                      fontWeight={500}
                      display={"flex"}
                      flexDirection={"column"}
                      width={"140px"}
                    >
                      Upload File:
                    </Typography>
                    {forms[formIndex].file === "" && (
                      <Button
                        buttonName={"Choose File"}
                        onClick={() => fileInputRef.current.click()}
                      />
                    )}
                    <input
                      type="file"
                      onChange={handleFileChange}
                      accept=".csv"
                      // value={forms[index].file}
                      ref={fileInputRef}
                      style={{ display: "none" }}
                    />
                    {forms[formIndex].file && (
                      <Typography
                        fontSize={{ xs: "10px", sm: "16px" }}
                        fontWeight={500}
                        display={"flex"}
                        flexDirection={"column"}
                        width={
                          forms[formIndex].file.length > 20
                            ? `${forms[formIndex].file.length * 10}px`
                            : "100%"
                        }
                      >
                        {forms[formIndex].file}
                      </Typography>
                    )}
                    {forms[formIndex].file && (
                      <Button
                        buttonName={"Remove File"}
                        onClick={handleFileRemove}
                        style={{ backgroundColor: "red", color: "white" }}
                      />
                    )}
                  </Stack>
                  <Divider />

                  <Stack
                    sx={{ display: "flex", flexDirection: "row", gap: "20px" }}
                  >
                    <label style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="checkbox"
                        style={{
                          marginRight: "10px",
                          height: "17px",
                          width: "17px",
                        }}
                        onChange={(e) => {
                          let newArray = [...forms];
                          newArray[formIndex].allowLocation = e.target.checked;
                          setForms(newArray);
                        }}
                        checked={forms[formIndex].allowLocation}
                        defaultChecked={forms[formIndex].allowLocation}
                      />
                      Allow Location
                    </label>
                  </Stack>
                  <Divider />
                  <Stack
                    sx={{ display: "flex", flexDirection: "row", gap: "20px" }}
                  >
                    <Typography
                      fontSize={{ xs: "10px", sm: "16px" }}
                      fontWeight={500}
                      mb={"2px"}
                      width={"100px"}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      Enter Your Option:
                    </Typography>
                    <Box sx={{ width: "100%" }}>
                      {forms &&
                        forms[formIndex] &&
                        forms[formIndex].data &&
                        forms[formIndex].data.map((ratings, index) => (
                          <Box
                            key={ratings.ids}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                              gap: "10px",
                            }}
                          >
                            <Box sx={{ flex: 1 }}>
                              <Input
                                placeholder={`Enter Option ${index + 1}`}
                                value={ratings.value}
                                onChange={(e) =>
                                  handleRating(ratings.ids, e.target.value)
                                }
                                sx={{ width: "100%" }}
                              />
                            </Box>
                            {/* {forms && forms[index] && forms[index].data && forms[index].data.length > 0 && ( */}
                            <Button
                              onClick={() => handleDeleteRating(ratings.ids)}
                              style={{ backgroundColor: "red", color: "white" }}
                              buttonName={
                                <HiOutlineTrash style={{ fontSize: "15px" }} />
                              }
                            />
                            {/* )} */}
                          </Box>
                        ))}
                      <Box sx={{ marginTop: "10px" }}>
                        <Button
                          onClick={handleAddRating}
                          buttonName={"Add Answer"}
                        />
                      </Box>
                    </Box>
                  </Stack>
                </Stack>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Stack spacing={2}>
                  <SkipLogic
                    logicQuestionSelecteData={logicQuestionSelecteData}
                    forms={forms}
                    setForms={setForms}
                    index={index}
                    setLogicQuestionSelecteData={setLogicQuestionSelecteData}
                    skipLogicData={skipLogicData}
                    skipLogicCondition={skipLogicCondition}
                    setSkipLogicCondition={setSkipLogicCondition}
                    displaylogicQuestionSelecteData={
                      displaylogicQuestionSelecteData
                    }
                    setDisplayLogicQuestionSelecteData={
                      setDisplayLogicQuestionSelecteData
                    }
                    displayLogicCondition={displayLogicCondition}
                    setDisplayLogicCondition={setDisplayLogicCondition}
                  />
                </Stack>
              </CustomTabPanel>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DropDownForm;
